/**
* Clearfix utility class
**/

.u-clearfix {
  @extend %clearfix;
}



/**
* Make element only visible for screen readers
**/

.u-screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px,1px,1px,1px);
  clip-path: polygon(0 0, 0 0, 0 0);
  overflow: hidden;
}


.u-full-height {
  height: 100%;
}

/**
* Make element sticky -- future depricated in favor of position: sticky
**/

.u-sticky-holder {
  position: relative;
}

@include mq($from: md) {
  .u-sticky {
    position: fixed;
    top: 30px;
    bottom: auto;
  }

  .u-sticky-bottom {
    position: absolute;
    bottom: 40px;
    top: auto;
  }
}



/**
* Vue.js V-cloak
**/

[v-cloak] {
  display: none;
  height: 1px;
  width: 1px;
  clip: rect(1px,1px,1px,1px);
  clip-path: polygon(0 0, 0 0, 0 0);
  overflow: hidden;
}



/**
* Flexbox alignment utility classes
**/

.u-flex {
  display: flex;
}

.u-justify-xs-start {
  justify-content: flex-start;
  text-align: start;
}

.u-justify-xs-center {
  justify-content: center;
  text-align: center;
}

.u-justify-xs-end {
  justify-content: flex-end;
  text-align: end;
}

.u-align-xs-top {
  align-items: flex-start;
}

.u-align-xs-center {
  align-items: center;
}

.u-align-xs-bottom {
  align-items: flex-end;
}

.u-justify-xs-around {
  justify-content: space-around;
}

.u-justify-xs-between {
  justify-content: space-between;
}

.u-order-xs-first {
  order: -1;
}

.u-order-xs-last {
  order: 1;
}

@include mq($from: sm) {
  .u-justify-sm-start {
    justify-content: flex-start;
    text-align: start;
  }

  .u-justify-sm-center {
    justify-content: center;
    text-align: center;
  }

  .u-justify-sm-end {
    justify-content: flex-end;
    text-align: end;
  }

  .u-align-sm-top {
    align-items: flex-start;
  }

  .u-align-sm-center {
    align-items: center;
  }

  .u-align-sm-bottom {
    align-items: flex-end;
  }

  .u-justify-sm-around {
    justify-content: space-around;
  }

  .u-justify-sm-between {
    justify-content: space-between;
  }

  .u-order-sm-first {
    order: -1;
  }

  .u-order-sm-last {
    order: 1;
  }
}

@include mq($from: md) {
  .u-justify-md-start {
    justify-content: flex-start;
    text-align: start;
  }

  .u-justify-md-center {
    justify-content: center;
    text-align: center;
  }

  .u-justify-md-end {
    justify-content: flex-end;
    text-align: end;
  }

  .u-align-md-top {
    align-items: flex-start;
  }

  .u-align-md-center {
    align-items: center;
  }

  .u-align-md-bottom {
    align-items: flex-end;
  }

  .u-justify-md-around {
    justify-content: space-around;
  }

  .u-justify-md-between {
    justify-content: space-between;
  }

  .u-order-md-first {
    order: -1;
  }

  .u-order-md-last {
    order: 1;
  }
}

@include mq($from: lg) {
  .u-justify-lg-start {
    justify-content: flex-start;
    text-align: start;
  }

  .u-justify-lg-center {
    justify-content: center;
    text-align: center;
  }

  .u-justify-lg-end {
    justify-content: flex-end;
    text-align: end;
  }

  .u-align-lg-top {
    align-items: flex-start;
  }

  .u-align-lg-center {
    align-items: center;
  }

  .u-align-lg-bottom {
    align-items: flex-end;
  }

  .u-justify-lg-around {
    justify-content: space-around;
  }

  .u-justify-lg-between {
    justify-content: space-between;
  }

  .u-order-lg-first {
    order: -1;
  }

  .u-order-lg-last {
    order: 1;
  }
}
