/**
* Clear element
**/

.u-clear {
  &-both {
    clear: both;
  }

  &-left {
    clear: left;
  }

  &-right {
    clear: right;
  }
}



/**
* Float element
**/

.u-float {
  &-left {
    float: left;
  }

  &-right {
    float: right;
  }

  &-none {
    float: none;
  }
}



/**
* Align text
**/

.u-text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }
}