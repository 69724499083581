/**
* Image pagepart
**/

.pp-image {
  img,
  video,
  .embed-responsive {
    width: 100%;
  }
}
