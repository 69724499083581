/**
*	Main scss file to be compiled into main.css
* Comment, uncomment and/or add files as needed
**/



/**
* 0_SETTINGS --- Global variables and config
**/

@import "~mathsass/dist/math";
@import "0_settings/global";
@import "0_settings/spacing";
@import "0_settings/colors";
@import "0_settings/typography";
@import "0_settings/z-index";



/**
* 1_TOOLS --- Default mixins and functions
**/

@import "1_tools/functions";
@import "1_tools/mixins";
@import "1_tools/animations";


/**
* 2_VENDOR --- Third-party scss, should always be imported via npm/node modules. Only place scss here if no module available.
**/

@import "~aos/dist/aos.css";
@import "~tippy.js/dist/tippy.css";
@import "~baguettebox.js/dist/baguetteBox.min.css";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.theme";
@import "~@rqrauhvmra/tobi/scss/tobi";


/**
* 3_GENERIC --- Ground zero styles
**/

@import "~reset-css/sass/reset";
@import "~normalize.css/normalize.css";
@import "3_generic/box-sizing";



/**
* 4_BASE --- Unclassed HTML elements (Type selectors)
**/

@import "4_base/base";
@import "4_base/page";
@import "4_base/headings";



/**
* 5_OBJECTS --- Cosmetic-free design patterns and layout structures
**/

@import "5_objects/content-wrapper";
@import "5_objects/container";
@import "5_objects/flexboxgrid";
@import "5_objects/pageparts";
@import "5_objects/entry";



/**
* 6_COMPONENTS --- Designed components, chunks of UI
**/

@import "6_components/header";
@import "6_components/header--fixed", "6_components/header--overlay", "6_components/header--transparent", "6_components/header--alt", "6_components/header--slidein", "6_components/header--bottom"; // Add/remove header modifiers as needed
@import "6_components/nav";
@import "6_components/top-nav";
@import "6_components/contact-menu";
@import "6_components/language-menu";
@import "6_components/main-menu";
@import "6_components/meta-menu";
@import "6_components/sidebar";
@import "6_components/collapse";
@import "6_components/jumbo";
@import "6_components/buttons";
@import "6_components/search";
@import "6_components/cookie";
@import "6_components/to-top";
@import "6_components/breadcrumbs";
@import "6_components/forms";
@import "6_components/dropzone";
@import "6_components/alerts";
@import "6_components/newsletter";
@import "6_components/footer";
@import "6_components/filters";
@import "6_components/pagination";
@import "6_components/previews";
@import "6_components/socials";
@import "6_components/tooltips";
@import "6_components/modals";
@import "6_components/card";
@import "6_components/intro";



/**
* 7_PAGEPARTS --- Pagepart specific styling
**/

@import "7_pageparts/call-to-action";
@import "7_pageparts/cookie-setting";
@import "7_pageparts/download";
@import "7_pageparts/faq";
@import "7_pageparts/gallery";
@import "7_pageparts/image-text";
@import "7_pageparts/image";
@import "7_pageparts/intro";
@import "7_pageparts/line";
@import "7_pageparts/link";
@import "7_pageparts/number";
@import "7_pageparts/quote";
@import "7_pageparts/reason";
@import "7_pageparts/team";
@import "7_pageparts/text-box";
@import "7_pageparts/video";



/**
* 8_PAGES --- Page specific styling
**/

@import "8_pages/home";
@import "8_pages/sitemap";



/**
* 9_UTILITIES --- Styling for utility items
**/

@import	"9_utilities/utilities";
@import	"9_utilities/colors";
@import	"9_utilities/spacing";
@import	"9_utilities/type";
