/**
* Cookie setting pagepart
**/

.pp-cookie {
  &__content {
    cursor: pointer;

    &.required {
      cursor: not-allowed;
    }

    h4 {
      font-size: font-size(1);
      line-height: line-height(1);
      margin-bottom: spacing(-3);

      span {
        display: block;
        font-size: font-size(-1);
        color: grey(0);
        font-weight: font-weight(0);
        font-style: italic;
      }
    }
  }
}
