/**
* Font variables and settings
* Leave the custom font "" if not used
**/

$custom-font:   "";
$heading-font:  "Comfortaa", cursive;
$body-font:     "Montserrat", "Helvetica", "Arial", sans-serif;



/**
* Font sizes
**/

$font-size: (
  -2: 1.2rem,
  -1: 1.4rem,
  0:  1.6rem,
  1:  1.8rem,
  2:  2.5rem,
  3:  3rem,
  4:  4rem,
  5:  5rem,
  6:  6rem,
  7:  7rem
);



/**
* Line heights
**/

$line-height: (
  0: 2.2rem,
  1: 2.5rem,
  2: 3rem,
  3: 3.8rem,
  4: 4.2rem,
  5: 5.2rem,
  6: 6.2rem,
  7: 7.2rem
);



/**
* Font weights
**/

$font-weight: (
  -1: 300,
  0:  400,
  1:  600,
  2:  700
);



/**
* Text transformations
**/

$text-none:  none;
$text-up:    uppercase;
$text-lower: lowercase;



/**
* Set custom webfont font-face
**/

@if variable-exists(custom-font) and $custom-font != ""  {
  @font-face {
    font-family: $custom-font;
    src: url("/build/css/fonts/" + $custom-font + ".eot?#iefix");
    src: url("/build/css/fonts/" + $custom-font + ".eot?#iefix") format("eot"),
         url("/build/css/fonts/" + $custom-font + ".woff2") format("woff2"),
         url("/build/css/fonts/" + $custom-font + ".woff") format("woff"),
         url("/build/css/fonts/" + $custom-font + ".ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: $custom-font;
    src: url("/build/css/fonts/" + $custom-font + "-bold.eot?#iefix");
    src: url("/build/css/fonts/" + $custom-font + "-bold.eot?#iefix") format("eot"),
         url("/build/css/fonts/" + $custom-font + "-bold.woff2") format("woff2"),
         url("/build/css/fonts/" + $custom-font + "-bold.woff") format("woff"),
         url("/build/css/fonts/" + $custom-font + "-bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
}
