/**
* Sitemap
**/

.p-sitemap {
  .sitemap {
    ul {
      margin-bottom: spacing(-1);

      a {
        color: $clr-text;

        &:hover {
          color: primary(0);
        }
      }

      li li {
        padding-left: spacing(0);
      }
    }
  }
}
