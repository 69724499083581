/**
* Number pagepart
**/

.pp-number {
  &__stat {
    display: flex;
    align-items: stretch;
    margin-bottom: spacing(0);

    @include mq($from: md) {
      margin-bottom: spacing(1);
    }
  }

  &__content {
    background-color: grey(-2);
    padding: spacing(1);
    border-radius: $corners;
    text-align: center;
    width: 100%;

    h3 {
      color: secondary(0);
      font-size: font-size(3);
      line-height: line-height(3);
      font-weight: font-weight(2);
      margin: spacing(0);
    }

    p {
      font-weight: font-weight(1);
      margin-bottom: 0;
    }

    svg {
      fill: $clr-white;
      max-width: 100%;
      max-height: 40px;
    }
  }

  &--home {
    h2 {
      color: primary(0);
    }
  }
}

// Home page
.p-home {
  .pp-number { @extend .pp-number--home; }
}
