/**
* Contact menu component
**/

.c-contact-menu {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  align-self: flex-start;

  @include mq($from: md) {
    flex: unset;
    align-self: flex-end;
  }

  li {
    a {
      color: grey(0);
      font-size: font-size(-2);
      display: block;
      padding: spacing(-3) spacing(-3) spacing(-3) spacing(1);
      @include transition(0);
      position: relative;
      max-width: 25px;
      text-indent: -9999px;

      @include mq($from: xs) {
        padding: spacing(-3) spacing(-3) spacing(-3) spacing(1);
        font-size: font-size(-1);
      }

      @include mq($from: sm) {
        font-size: font-size(0);
        text-indent: unset;
        max-width: unset;
      }

      @include mq($from: md) {
        padding: spacing(-3) spacing(-3) spacing(-3) spacing(1);
      }

      svg {
        fill: primary(0);
        @include transition(0);
        position: absolute;
        top: 50%;
        left: spacing(-2);
        transform: translateY(-50%);

        @include mq($from: md) {
          left: spacing(-2);
        }
      }

      &:hover,
      &:focus {
        color: $clr-white;
        background-color: primary(0);

        svg {
          fill: $clr-white;
        }
      }
    }
  }
}
