/**
* Quote pagepart
**/

.pp-quote {
  padding: spacing(0) 0; // Quote gets extra spacing
  position: relative;

  @include mq($from: md) {
    padding: spacing(1) 0 spacing(1) spacing(2);
  }

  svg {
    display: none;

    @include mq($from: md) {
      display: block;
      position: absolute;
      top: 45px;
      left: 0;
      fill: primary(0);
    }
  }

  &__content {
    font-size: font-size(2);
    line-height: line-height(2);
    font-weight: font-weight(-1);
    color: grey(1);
    margin-bottom: spacing(-1);
  }

  &__author {
    color: primary(0);
    font-weight: font-weight(1);
  }

  &__media {
    text-align: center;

    @include mq($from: md) {
      text-align: right;
      order: 1;
    }
  }

  &__image {
    border: $border primary(0);
    width: 180px;
    height: 180px;
    border-radius: $circle;
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    background-color: $clr-white;
    margin-bottom: spacing(0);

    @include mq($from: md) {
      margin-bottom: 0;
    }

    img {
      opacity: 0;
    }
  }

  video,
  .embed-responsive {
    width: 100%;
  }

  &--home {
    background-color: grey(-2);
    padding: spacing(2) 0;

    .o-container {
      position: relative;

      .pp-quote__content,
      .pp-quote__author {
        @include mq($from: md) {
          padding-left: spacing(2);
        }
      }
    }

    svg {
      top: 7px;
      left: spacing(-1);
    }
  }
}

// Home page
.p-home {
  .pp-quote { @extend .pp-quote--home; }
}
