/**
* Alerts component
**/

.c-alert {
  position: fixed;
  z-index: z-index(alert);
  left: 0;
  right: 0;
  top: 0;
  max-height: 100px;
  text-align: center;
  padding: spacing(0);
  overflow: hidden;
  @include transition(0);

  &--success {
    color: confirm(0) ;
    background-color: confirm(-2);
  }

  &--error {
    color: error(0);
    background-color: error(-3);
  }

  &.is-hidden {
    padding: 0;
    max-height: 0;
  }
}
