/**
* Base page
**/

html {
  font-size: 62.5%;
  line-height: line-height(1);
  -webkit-font-smoothing: antialiased;
  max-width: 100vw;

  &.is-hidden {
    overflow: hidden;
  }
}

body {
  color: $clr-text;
  background-color: secondary(-3);
  font-family: $body-font;
  font-size: font-size(0);
  line-height: line-height(1);
  overflow-x: hidden;
  max-width: 100vw;

  @include mq($until: sm) {
    &.has-bottom-header {
      padding-bottom: $header-height;
      padding-bottom: var(--header-height);
    }

    &.is-animating:not([class*=fixed]):not([class*=bottom]) {
      padding-top: $header-height;
      padding-top: var(--header-height);
    }

  }
}
