/**
* General page parts
**/

.o-page-part {
  margin-bottom: spacing(0);

  @include mq($from: md) {
    margin-bottom: spacing(2);
  }

  &--overview {
  	position: relative;
  	background: primary(0);
  	padding: spacing(0);
  	&:after {
  	  content: '';
  	  position: absolute;
  	  left: 0;
  	  top: 0;
  	  width: 100%;
  	  height: 100%;
  	  background: url('../../static/pattern.jpg');
  	  background-repeat: repeat;
  	  opacity: .1;
  	}
  }
}
