/**
* Entry
**/

.o-entry {

  position: relative;

  // &:after {
  //   content:'';
  //   position: absolute;
  //   right: 0;
  //   bottom: 0px;
  //   width: 40px;
  //   height: 40px;
  //   border-right: 2px solid secondary(0);
  //   border-bottom: 2px solid secondary(0);
  // }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & > .embed-responsive { // Youtube / Vimeo
    position: relative;
    padding: spacing(0) 0 56.25%; /* 16:9 */
    height: 0;
    margin: spacing(0) auto;

    @include mq($from: md) {
      margin: spacing(1) auto;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > video { // Video
    width: 100%;
  }

  ul,
  ol { // Lists
    color: $clr-text;
    margin-bottom: spacing(0);

    li {
      position: relative;
      padding: spacing(-3) spacing(-2) spacing(-3) spacing(0);
      margin-bottom: 0;
      line-height: line-height(0);

      &::before {
        content: "";
        background-color: primary(0);
        position: absolute;
        top: 14px;
        left: 6px;
        bottom: 6px;
        width: 5px;
        height: 5px;
        border-radius: $circle;
      }
    }
  }

  ol { // Ordered lists only
    list-style: none;
    counter-reset: item;

    li {
      counter-increment: item;

      &::before {
        display: none;
      }

      &::after {
        content: counter(item);
        color: primary(0);
        font-weight: font-weight(1);
        padding: 0 spacing(1) 0 0;
        position: absolute;
        left: 5px;
        top: 4px;
      }
    }
  }


    table { // Tables
      margin: spacing(0) auto;
      width: 100%;

      @include mq($from: md) {
        margin: spacing(1) auto;
      }

      th,
      td {
        padding: spacing(-1) spacing(0);
        text-align: left;
        border: 1px solid primary(-2);
        p {
          margin-bottom: 0;
        }
      }

      tbody {
        tr {
          &:first-child {
            background-color: grey(-3);
          }
        }
      }
    }
  
}
