/**
* Baseline grid settings
**/

$global-baseline:           8px !default; // Every spacing metric should be based on this
$global-spacing-factor-xs:  1 !default;
$global-spacing-factor-s:   2 !default;
$global-spacing-factor:     3 !default;
$global-spacing-factor-l:   4 !default;
$global-spacing-factor-xl:  6 !default;
$global-spacing-factor-xxl: 8 !default;
$global-spacing-factor-3xl: 12 !default;



/**
* Spacing values are determined based on your projectâs global baseline grid
**/

$global-spacings: (
  -3: $global-baseline / $global-spacing-factor-s,   // 4px
  -2: $global-baseline * $global-spacing-factor-xs,  // 8px
  -1: $global-baseline * $global-spacing-factor-s,   // 16px
  0:  $global-baseline * $global-spacing-factor,     // 24px
  1:  $global-baseline * $global-spacing-factor-l,   // 32px
  2:  $global-baseline * $global-spacing-factor-xl,  // 48px
  3:  $global-baseline * $global-spacing-factor-xxl, // 64px
  4:  $global-baseline * $global-spacing-factor-3xl  // 96px
) !default;



/**
* Check that the chosen size factors are unitless, integer numbers
**/

@each $_spacing in
  $global-spacing-factor-xs
  $global-spacing-factor-s
  $global-spacing-factor
  $global-spacing-factor-l
  $global-spacing-factor-xl
  $global-spacing-factor-xxl
  $global-spacing-factor-3xl {

  @if (type-of($_spacing) == number) {
    @if (unitless($_spacing) == false) {
      @warn "`#{$_spacing}` needs to be unitless.";
    }

    @if ($_spacing != ceil($_spacing)) {
      @warn "`#{$_spacing}` needs to be an integer.";
    }
  } @else {
    @warn "`#{$_spacing}` needs to be a number.";
  }
}
