/**
* Header component, slide in mobile menu variant
* - Make the navigation slide into view (default from the right to left, only on mobile)
**/

$slidedistance: 100vw; // use negative value to slide from/to the left
$slidein-speed: .3s;

.c-header {

  &--slidein {
    @include mq($until: $header-breakpoint) {
      background-color: transparent;
      overflow-x: hidden;
      overflow-y: hidden;
      max-height: $header-height;
      max-height: var(--header-height);
      transition: max-height .001s;

      &::before,
      [class^=c-nav--],
      .c-header__nav {
        will-change: transform, left;
        transition: left $slidein-speed ease-in-out;
        left: $slidedistance; // Change to change direction
      }

      .c-header__nav [class^=c-nav--] {
        transition: none;
        left: 0;
      }

      &.is-animating {
        background-color: transparent;
        top: 0;
        position: fixed;
        overflow: visible;

        .c-header__container {

          .c-header__nav {
            display: flex;
          }

        }

        &[class*=bottom] { // c-header--bottom specific overrides when animating
          padding: 0;

          .c-header__container {
            position: fixed;

            .c-header__logo {
              position: fixed;
              bottom: $header-toppadding;
              z-index: z-index(menu);
            }

            .c-header__menu-toggle {
              position: fixed;
              z-index: z-index(bottom-header-toggle);
              bottom: spacing(1);
              top: auto;
            }

            .c-nav--primary {
              margin-top: 0;
            }

          }
        }
      }

      &::before {
        content: "";
        display: block;
        background-color: $clr-header-bg;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        position: fixed;
        z-index: -1;
      }

      &[class*=overlay] {
        &::before {
          background-color: $bgclr-overlay;
        }
      }

      [class^=c-nav--],
      .c-header__nav {
        order: 2;
        position: relative;
        width: 100%;
      }

      .c-header__container {
        flex-direction: column;
        position: relative;
        align-items: initial;
        pointer-events: none;

        .c-nav--primary,
        .c-nav--secondary {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: none;
          order: 1;
        }

        .c-nav__item {
          display: inline-block;
          padding: $header-toppadding 0;
        }

        .c-nav__item--search {
          order: 0;
          margin-left: unset;
        }

        .c-header__menu-toggle {
          position: absolute;
          right: $header-toppadding / 2;
          top: 9px;
          pointer-events: initial;
        }
      }

      .c-header__logo {
        pointer-events: initial;
        order: 1;

        &::after {
          content: "";
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          background: $clr-header-bg;
          height: $header-height;
          height: var(--header-height);
          z-index: -1;
        }
      }

      &:not([class*=fixed]) {
        .c-header__logo::after {
          top: -#{$header-toppadding};
          position: absolute;
        }
      }

      &[class*=bottom] {
        position: fixed;
        transition: none;

        .c-header__container {

          .c-nav--primary {
            margin: 0;
          }
        }

        .c-header__logo::after {
          top: auto;
          bottom: 0;
          position: fixed;
        }
      }

      .c-header__menu-toggle {
        order: 1;
      }

      &.is-active {
        background-color: transparent;

        .c-header__container {
          position: relative;
        }

        &::before,
        [class^=c-nav--],
        .c-header__nav {
          left: 0;
        }

      }

      &:not([class*=active]):not([class*=animating]) {

        .c-header__container {

          [class^=c-nav--] {
            height: 0;
            margin: 0;
            overflow: hidden;
          }

        }

      }

    }
  }

}
