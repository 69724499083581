/**
* Breadcrumbs component
**/

.c-breadcrumb {
  background-color: grey(-2);
  padding: spacing(0) 0;

  ul {
    margin-bottom: 0;

    li {
      display: inline-block;
      position: relative;
      padding-right: spacing(0);
      margin-right: spacing(-3);
      font-size: font-size(0);

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        fill: grey(-1);
        width: 13px;
        height: auto;
      }

      span {
        color: grey(0);
      }

      a {
        color: grey(-1);
        @include transition(0);

        &:hover {
          color: primary(1);
        }
      }
    }
  }
}
