/**
* Content wrapper
**/

.o-content-wrapper {
  position: relative;

  & > .o-container {
    margin: spacing(0) auto;

    @include mq($from: md) {
      margin: spacing(2) auto;
    }

    @include mq($from: lg) {
      margin: spacing(3) auto;
    }
  }
}
