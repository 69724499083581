/**
* Forms component (elements)
**/

.c-form {
  margin-bottom: spacing(0);

  label {
    cursor: pointer;
  }

  select {
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
    background: transparent url("../../images/arrows/down.svg") no-repeat right spacing(-1) center;
  }

  .c-form__label ~ .c-form__feedback,
  .c-form__checkbox ~ .c-form__feedback,
  .c-form__consensus__label ~ .c-form__feedback,
  textarea + .c-form__feedback {
    svg {
      display: block;
    }
  }

  textarea {
    min-height: 150px;

    @include mq($from: md) {
      min-height: 250px;
    }
  }

  &__group {
    position: relative;
    margin-bottom: spacing(0);
  }

  &__label {
    color: primary(0);
    font-size: font-size(0);
    font-weight: font-weight(1);
    display: block;
    margin-bottom: spacing(-3);

    &.required {
      &::after {
        display: none;
      }
      .c-form__labeltext {
        &::after {
          content: " *";
        }
      }
    }
  }

  &__labeltext {
    display: inline-block;
    position: relative;
  }

  &__control {
    color: $clr-text;
    @include placeholder(#{grey(-1)});
    font-size: font-size(0);
    border-radius: $corners;
    display: block;
    width: 100%;
    border: $border grey(-1);
    padding: spacing(-1);

    &:hover,
    &:focus {
      border: $border primary(0);
    }
  }

  &__feedback {
    color: error(0);
    background-color: error(-2);
    border-radius: $corners;
    padding: spacing(-2) spacing(1) spacing(-2) spacing(-1);
    margin-top: spacing(-2);
    position: relative;

    svg {
      display: none;
      position: absolute;
      top: 50%;
      right: spacing(-1);
      fill: error(0);
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__consensus {
    @extend .c-form__checkbox;

    &__label {
      &.required {
        &::after {
          content: " *";
        }
      }

      p {
        display: inline;
      }
    }
  }

  &__upload {
    cursor: pointer;

    label {
      display: block;
      cursor: pointer;
      font-weight: font-weight(1);
      color: primary(0);

      &:focus {
        outline: none;
      }

      &.required {
        &::after {
          display: none;
        }
      }
    }

    input[type=file] {
      width: .1px;
      height: .1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: z(form, file);

      &:focus {
        outline: none;
      }
    }

    span {
      cursor: pointer;
      color: $clr-text;
      font-size: font-size(0);
      border: $border grey(-1);
      border-radius: $corners;
      display: block;
      width: 100%;
      padding: 13px spacing(0) 13px spacing(1);
      background: url("../../images/general/upload.svg") no-repeat left 10px center;
      background-size: 15px;
      margin-top: spacing(-3);
      @include transition(1);
      overflow: hidden;
      max-height: 52px;
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        border: $border primary(0);
      }
    }
  }

  &--danger {
    &__control {
      border: $border error(0);

      &:hover,
      &:focus {
        border: $border primary(0);
      }
    }
  }

  &--success {
    display: none;

    &__message {
      display: none;
    }

    & + .c-form--success__message {
      display: block;
    }
  }

  &--search {
    position: relative;

    input {
      border: 1px solid $clr-black;
      padding: spacing(-3);
      padding-right: 35px;
      width: 100%;

      &:hover,
      &:focus,
      &:active {
        @include transition(0);
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      width: 35px;

      svg {
        @extend %inline-svg;
        fill: primary(0);
      }
    }
  }



  /**
  * Custom layout for formbuilder
  **/

  @for $i from 1 through 6 {
    .o-col-md-#{$i} {
      @include mq($until: md) {
        flex-basis: 50%;
        max-width: 50%;
      }

      @include mq($until: xs) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  @for $i from 7 through 12 {
    .o-col-md-#{$i} {
      @include mq($until: md) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }



  /**
  * Custom styling for Craft Freeform
  **/

  &__nav {
    display: flex;
  }

}



/**
* Checkbox/radio
**/

.c-form__checkbox,
.c-form__radio {
  label {
    position: relative;
    padding-left: spacing(1);
    display: block;

    &.required {
      &::after {
        content: " *";
      }
    }

    span:empty {
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        border: $border grey(0);
        position: absolute;
        top: 4px;
        left: 0;
      }

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: primary(0);
        position: absolute;
        top: 7px;
        left: 3px;
        opacity: 0;
        transition: transition(0);
      }

      &:hover {
        &::after {
          opacity: .5;
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked + span {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &--inline {
    display: inline-block;
    margin-right: spacing(1);
  }

}

.c-form__radio {
  label {
    span {
      &::before,
      &::after {
        border-radius: $circle;
      }
    }
  }
}



/**
* Captcha
**/

.g-recaptcha {
  margin-bottom: spacing(0);

  .grecaptcha-badge {
    /* stylelint-disable-next-line declaration-no-important */
    position: static !important;
  }
}



/**
* Download Request form
**/

form[name=download_request] {
  .btn-default {
    @extend .c-btn;
    @extend .c-btn--primary;
  }
}
