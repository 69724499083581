/**
* Header component, mobile bottom variant
* - Turn the mobile header into a bottom toolbar for better ux
* - Always fixed, uncompatible with c-header--fixed
**/

$clr-overlay_menuitem: primary(-3);
$bgclr-overlay: primary(0);

.c-header {

  &--overlay {
    padding-bottom: spacing(0);
    max-height: $header-height;
    max-height: var(--header-height);
    @include transition(1);

    &[class*=transparent]:not([class*=fixed]) {
      position: absolute;
    }

    .c-header__menu-toggle {
      display: block;
    }

    .c-nav--primary {

      &::after {
        display: none;
      }

      .c-nav__link {
        color: $clr-overlay_menuitem;
        @include fluid-type(breakpoint(sm), breakpoint(lg), font-size(1), font-size(3));
      }

    }

    .c-nav--secondary {

      .c-nav__link {
        color: $clr-overlay_menuitem;
        @include fluid-type(breakpoint(sm), breakpoint(lg), font-size(0), font-size(1));
      }

    }

    @include mq($from: $header-breakpoint) {

      .c-header__nav {
        display: none;
      }

    }

    &.is-active {
      background-color: $bgclr-overlay;
      position: fixed;

      .c-header__logo {

        svg g {
          transition: fill .5s ease-in-out;
          fill: $clr-overlay_menuitem;
        }

      }

      .c-header__menu-toggle {

        span {
          transition: background-color .5s ease-in-out;
          background-color: $clr-overlay_menuitem;
        }

      }

      .c-language-menu {
        margin-left: 0;
        border: 0;
        padding-left: 0;

        &__toggle {
          color: $clr-overlay_menuitem;

          svg {
            fill: $clr-overlay_menuitem;
          }
        }

        &__content {
          left: 0;
          right: auto;
        }
      }

      &[class*=bottom] {
        .c-header__logo::after {
          background-color: $bgclr-overlay;
        }
      }

      @include mq($from: sm) {

        .c-nav--primary {
          margin-top: 0;
        }

        .c-header__container {
          height: 100%;
        }

        .c-header__nav {
          display: flex;
          flex-direction: row;
          align-self: center;
          align-items: center;
          width: 100%;

          [class*=nav--] {
            width: 50%;
            flex-direction: column;
            align-items: center;

            &:first-child {
              border-right: 1px solid $clr-overlay_menuitem;
            }
          }

          .c-nav__items {
            flex-direction: column;
            padding-left: 0;
            text-align: left;
          }

          .c-nav--secondary {

            .c-nav__item {
              text-align: left;

              + .c-nav__item {
                padding-left: 0;
              }

            }

          }

        }

      }

    }

    &.is-scrolled {
      .c-nav__link {
        color: $clr-overlay_menuitem;
      }
    }

  }

}
