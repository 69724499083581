/**
* General button styles
**/

.c-btn {
  border-radius: $btn-corners;
  font-size: font-size(0);
  border: $border transparent;
  padding: $btn_padding;
  font-weight: font-weight(1);
  @include transition(0);
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: inline-block; // Set as default to fix normal flow

  &.is-disabled {
    opacity: .6;
    cursor: not-allowed;
    pointer-events: none;
  }

  > svg {
    @extend %inline-svg;
    margin-right: .5em;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
      margin-left: .5em;
    }

    &:only-child {
      margin: 0;
    }

    &+ svg:last-child {
      margin-left: 0;
    }
  }

  &--primary { // Button with full primary color (-o has a primary outline)
    color: $clr-white;
    background-color: third(0);
    border: $border third(0);

    svg {
      fill: $clr-white;
    }

    &:hover,
    &:focus {
      color: $clr-white;
      background-color: primary(0);
      border: $border primary(0);
    }

    &--o {
      color: primary(0);
      background-color: transparent;
      border: $border primary(0);

      svg {
        fill: primary(0);
      }

      &:hover,
      &:focus {
        color: primary(1);
        background-color: transparent;
        border: $border primary(1);
      }
    }
  }

  &--secondary { // Button with full secondary color (-o has a secondary outline)
    color: $clr-white;
    background-color: third(0);
    border: $border third(0);

    svg {
      fill: $clr-white;
    }

    &:hover,
    &:focus {
      color: $clr-white;
      background-color: third(1);
      border: $border third(1);
    }

    &--o {
      color: secondary(0);
      background-color: transparent;
      border: $border secondary(0);

      svg {
        fill: secondary(0);
      }

      &:hover,
      &:focus {
        color: secondary(1);
        background-color: transparent;
        border: $border secondary(1);
      }
    }
  }

  &--white { // Button with full white color (-o has a white outline)
    color: primary(0);
    background-color: $clr-white;
    border: $border $clr-white;

    svg {
      fill: primary(0);
    }

    &:hover,
    &:focus {
      color: $clr-white;
      background-color: primary(0);
      border: $border primary(0);

      svg {
        fill: $clr-white;
      }
    }

    &--o {
      color: $clr-white;
      background-color: transparent;
      border: $border $clr-white;

      svg {
        fill: $clr-white;
      }

      &:hover,
      &:focus {
        color: primary(0);
        background-color: $clr-white;
        border: $border $clr-white;

        svg {
          fill: primary(0);
        }
      }
    }
  }

  &--text { // Button inline in text
    border-radius: 0;
    padding: 0;
    text-transform: $text-none;
    color: primary(0);

    svg {
      fill: primary(0);
    }

    &:hover,
    &:focus {
      color: primary(1);

      svg {
        fill: primary(1);
      }
    }
  }



  /**
	* Button containing a call to action icon, usually an arrow
	* Should be used in combining with a styling class (primary, secondary, white)
	**/

  &--cta {
    padding-right: spacing(1);
    position: relative;

    svg {
      right: spacing(-1);
    }

    &:hover,
    &:focus {
      padding-right: spacing(2);
    }
  }

  &--back {
    padding-left: spacing(1);
    position: relative;

    svg {
      left: spacing(-1);
    }

    &:hover,
    &:focus {
      padding-left: spacing(2);
    }
  }

  &--download { // Variant with download icon
    @extend .c-btn--cta;
    padding-right: spacing(2);

    &:hover,
    &:focus {
      padding-right: spacing(3);
    }
  }
}



/**
* Button grouping
**/

.c-btn-group {
  .c-btn {

    margin-bottom: spacing(-2);
    display: inline-block;

    @include mq($from: md) {

      margin-bottom: 0;
    }
  }
}
