/**
* Intro pagepart
**/

.pp-video {
  position: relative;
  &__play {
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
    background: primary(0);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    g {
    	stroke: none;
    }

    path {
      transform: scale(1.5);
      transform-origin: 50% 50%;
    }

    &:hover,
    &:focus {
    	background: primary(1);
    }
  }

  &--home {
    max-height: 400px;
    overflow: hidden;
    margin-left: -(spacing(0) / 2);
    margin-right: -(spacing(0) / 2);
    margin-bottom: 0;
    @include mq($from: $container-width) {
      max-height: 500px;
      margin-left: calc(-1 * (100vw - #{$container-width}) / 2);
      margin-right: calc(-1 * (100vw - #{$container-width}) / 2);
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}


// Home page
// .p-home {
//   .pp-video { @extend .pp-video--home; }
// }

.tobi {
  background-color: primary(1) !important;
}