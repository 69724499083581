/**
* Cookie component
**/

.c-cookie {
  position: fixed;
  z-index: z(cookie);
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $clr-black;
    opacity: .85;
  }

  &__holder {
    position: absolute;
    background: $clr-white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    color: $clr-text;
    padding: spacing(1);
    box-shadow: shadow(0);
    max-width: 90%;
    border: $border primary(0);

    @include mq($from: md) {
      max-width: 60%;
    }
  }

  &__buttons {
    text-align: center;
    margin-top: spacing(0);
    margin-left: auto;
  }

  @include mq($from: md) {
    bottom: spacing(-1);
    left: spacing(-1);
  }

  &__settings {
    &-title {
      color: primary(0) !important;
      font-size: font-size(1);
      font-weight: font-weight(1);
    }
  }

  p {
    margin: 0 0 spacing(0) 0;
    width: 100%;
    max-width: 100%;
    font-size: font-size(-1);
    line-height: line-height(0);

    @include mq($from: md) {
      margin: 0;
      max-width: 500px;
    }

    a {
      color: $clr-text;
      border-bottom: $border $clr-white;
      @include transition(0);

      &:hover {
        border-bottom: 3px solid $clr-white;
      }
    }
  }

  .c-btn {
    @include mq($from: md) {
      margin-left: spacing(0);
    }

    @include mq($until: xs) {
      &:last-child {
        margin-top: spacing(-1);
      }
    }
  }

  .c-btn-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include mq($from: md) {
      width: auto;
    }
  }

  &__close {
    margin-top: spacing(0);
  }

  &.is-hidden {
    height: 0;
    padding: 0;
    border: none;
    left: -400px;
    bottom: -100px;
  }

  .is-hidden {
    // Saved cookie settings - never show
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
