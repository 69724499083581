/**
* Tooltips component
**/

.c-tooltip {
  cursor: pointer;
  position: absolute;
  height: 100%;
  display: inline-flex;
  align-items: center;
  right: -1.5em;

  svg {
    width: 1em;
    height: 1em;
    stroke: $clr-text;
    @include transition(0);
  }

  &:hover {
    svg {
      stroke: primary(0);
    }
  }
}



/**
* Custom tippy tooltip 'yappa' theme
**/

$locations: top bottom left right;

@each $location in $locations {
  .tippy-popper[x-placement^="#{$location}"] .tippy-tooltip.yappa-theme {
    background: $clr-text;
    font-size: font-size(-1);
    padding: spacing(-1) spacing(0);

    .tippy-arrow {
      border-#{$location}-color: $clr-text;
    }
  }
}
