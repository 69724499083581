/**
* Header component, transparent modifier
* - Add the transparent modifier to make the header and primary navbar transparent and overlay the jumbo
**/

.c-header {

  &--transparent:not([class*=active]) {

    &:not([class*=fixed]):not([class*=overlay]) {
      position: absolute;
      top: 0;
      &[class*=bottom] {
        @include mq($until: $header-breakpoint) {
          top: auto;
          position: fixed;
        }
      }
    }


    &:not([class*=scrolled]) {
      background-color: transparent;

      .c-nav--primary {
        background-color: transparent;
        &::after {
          display: none;
        }
      }
      .c-nav__link {
        color: $clr-text;
      }
    }

    &.is-hidden {
      background-color: $clr-header-bg;
    }

    + section:not([class*=jumbo]),
    + main {
      margin-top: $header-height;
      margin-top: var(--header-height);
    }

    @include mq($from: $header-breakpoint) {
      top: 0;
    }

    @include mq($until: $header-breakpoint) {

      &.c-header--bottom {
        + section:not([class*=jumbo]),
        + main {
          margin-top: 0;
        }
      }

    }

    @include mq($until: $header-breakpoint) {
      &:not([class*=bottom]) {

        + .c-jumbo {
          padding-top: $header-height;
          padding-top: var(--header-height);
        }

      }
    }
  }

}
