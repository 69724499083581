/**
* Modals
**/

.c-modal {
  background-color: rgba($clr-black, .8);
  position: fixed;
  z-index: z(modal);
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  @include transition(0);

  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: z(modal, close);
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      fill: grey(-2);
      width: 14px;
      height: 14px;
      @include transition(0);
    }

    &:hover {
      svg {
        fill: primary(0);
      }
    }
  }

  &__content {
    background-color: $clr-white;
    position: relative;
    z-index: z(modal, content);
    width: 900px;
    max-width: 95%;
    margin: spacing(2) auto;
    box-shadow: shadow(-1);
    padding: spacing(1);
    border-radius: $corners;

    @include mq($from: sm) {
      margin: spacing(3) auto;
      padding: spacing(2);
    }

    @include mq($from: md) {
      margin: spacing(4) auto;
      padding: spacing(3);
    }
  }

  &.is-active {
    top: 0%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
  }
}



/**
* Custom modal for cookie settings
**/

.c-modal--cookie {
  @extend .c-modal;
  z-index: z(cookie-modal);

  .c-modal__view {
    min-height: 200px;

    &.is-hidden {
      background: url("../images/general/preloader-alt.svg") no-repeat center;
    }
  }

  .c-modal__custom {
    .o-entry {
      border-bottom: $border grey(-2);
      padding-bottom: spacing(1);
      margin-bottom: spacing(1);
    }
  }

  .c-cookie__settings {
    h3 {
      text-align: center;
    }

    .c-btn-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      border-top: $border grey(-2);
      padding-top: spacing(1);
      margin-top: spacing(1);
    }
  }
}
