/**
* Call to action pagepart
**/

.pp-cta {
  background-color: grey(-2);

  h2,
  p {
    margin-top: 0;
  }

  .btn {
    display: inline-block;
  }

  &__content {
    flex-basis: 100%;
    padding: spacing(0);

    @include mq($from: sm) {
      padding: spacing(1);
    }
  }

  &__image {
    background-size: cover;
    background-position: center;
    display: none;
    flex: 1 0 240px;
    align-self: stretch;

    @include mq($from: sm) {
      display: block;
    }

    @include mq($from: md) {
      flex: 1 0 300px;
    }

    img {
      opacity: 0;
    }
  }

  video,
  .embed-responsive {
    width: 100%;
  }

  &--media {
    @include mq($from: sm) {
      @include flex-(center);
    }
  }

  &--home {
    background-color: transparent;

    .pp-cta__image {
      img {
        width: 100%;
        margin-bottom: spacing(0);
        opacity: 1;

        @include mq($from: sm) {
          margin-bottom: 0;
        }
      }
    }

    .pp-cta__content {
      padding: 0;

      @include mq($from: sm) {
        padding: 0 0 0 spacing(0);
      }

      @include mq($from: md) {
        padding: 0 0 0 spacing(1);
      }
    }
  }
}

// Home page
.p-home {
  .pp-cta { @extend .pp-cta--home; }
}
