/**
* Language menu
**/

.c-language-menu {
  margin-left: 0;
  position: relative;
  z-index: z(language);

  @include mq($from: sm) {
    margin-left: spacing(0);
  }

  &__toggle {
    white-space: nowrap;
    color: grey(0);
    @include transition(0);

    svg {
      transform: rotate(0deg);
      fill: primary(0);
      @include transition(0);
      position: relative;
      top: -3px;
    }

    &:hover,
    &:focus {
      color: primary(0);
    }

    &.is-active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    position: absolute;
    z-index: z(collapse);
    top: 120%;
    right: 0;
    background-color: $clr-white;
    border-radius: $corners;
    box-shadow: shadow(-1);
    max-height: 0;
    overflow: hidden;
    @include transition(0);

    &.is-active {
      max-height: 300px;
      @include transition(1);
    }

    a {
      color: $clr-text;
      display: block;
      padding: spacing(-2) spacing(-1);

      &:not(:first-child) {
        border-top: $border grey(-2);
      }

      &:hover {
        color: primary(0);
      }
    }

    @include mq($until: sm) {
      position: relative;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
