/**
* Base headings
**/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  letter-spacing: -0.025em;
}

h1,
.h1 {
  color: primary(0);
  font-size: font-size(3);
  margin-bottom: spacing(1);
  font-weight: font-weight(2);
  font-family: $heading-font;
  line-height: 1.2;

  @include mq($from: md) {
    font-size: font-size(4);
  }
}

h2,
.h2 {
  color: secondary(0);
  font-size: font-size(2);
  margin-bottom: spacing(0);
  font-weight: font-weight(2);
  line-height: line-height(2);
  font-family: $heading-font;

  @include mq($from: md) {
    font-size: font-size(3);
    line-height: line-height(3);
  }
}

h3,
.h3 {
  color: secondary(0);
  font-size: font-size(1);
  margin-bottom: spacing(0);
  font-weight: font-weight(1);
  line-height: line-height(1);
  font-family: $heading-font;

  @include mq($from: md) {
    font-size: font-size(2);
    line-height: line-height(2);
  }
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  color: primary(0);
  font-size: font-size(1);
  font-weight: font-weight(1);
  margin-bottom: spacing(0);
  font-family: $heading-font;

  @include mq($from: md) {
    font-size: font-size(2);
    line-height: line-height(2);
  }
}
