/**
* card component
**/

.c-card {
	position: relative;
  padding: 0;
  display: flex;
  @include transition(0);

  @include mq($from: sm) {
    flex-direction: column;
  }

  p {
    margin-bottom: spacing(-1);
  }

  &__image {
    position: relative;
    min-height: 1px;
  }

  &__date {
    color: secondary(0);
  }

  &__title,
  &__title a {
    color: primary(0);
    text-decoration: none;
    
    &-lg {
      line-height: 1.2;
      @include fluid-type(breakpoint(xs), breakpoint(lg), font-size(1), font-size(3));
    }
  }

  // &__content {
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;
  // }

  &__icon {
    @include transition(1);
    margin: spacing(-1) 0;
    max-height: 95px;
    @include mq($until: sm) {
      margin-right: spacing(1);
      margin-top: 0;
    }
    // min-height: 110px;
    // svg {
    //   max-width: 150px;
    //   height: auto;
    // }
  }

  &__link {
  	color: primary(0);
    font-weight: font-weight(1);
    // font-size: font-size(1);
    margin-top: auto;
  }

  // &__meta {
  //   position: absolute;
  //   left: 0;
  //   top: spacing(0);
  //   color: secondary(0);
  //   font-weight: font-weight(1);
  //   font-size: font-size(0);
  //   background: $clr-white;
  //   padding: spacing(-2) spacing(-1) spacing(-2) spacing(1);
  //   z-index: 1;
  //   border-top-right-radius: 6px;
  //   border-bottom-right-radius: 6px;
  // }

  &--bg {
    background-color: primary(-3);
    padding: spacing(-1) spacing(-2);
    @include mq($until: lg) {
      padding: spacing(-2) spacing(-3);
    }
    &:hover,
    &:focus {
      transform: translateY(-10px);
    }
  }

  &--shadow {
    padding: spacing(-1) spacing(-3);
    box-shadow: 0 3px 20px 0 rgba(0,0,0,0.15);

    &:hover,
    &:focus {
      &:after {
        @include transition(1);
        content: '';
        position: absolute;
        bottom: 0;
        background: secondary(0);
        height: 5px;
        width: 100%;
      }
    }
  }

  &--icon {
    text-align: center;

    .c-card__image {
      max-height: 100px;
    }
    .c-card__title {
      font-size: font-size(1);

      margin-bottom: 0;
      a {
        font-weight: font-weight(2);
        color: $clr-white;
      }
    }
    
    svg {
      max-width: 80px;
      fill: primary(2);
      margin-bottom: spacing(-1);
    }
    &:focus,
    &:hover {
      svg {
        transform: scale(1.1);
      }
      .c-card__title {
        text-decoration: underline;
      }
    }
  }


  &--team {
    overflow: hidden;
    border-radius: 0;

    a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    .c-card__image {
      position: relative;
      @include transition(0);
      &:after {
        content: '';
        position: absolute;
        top: 70%;
        right: -10px;
        background: secondary(-3);
        width: 80%;
        height: 250px;
        transform: rotate(-15deg);
        padding-top: 100px;
      }
    }
    .c-card__content {
      padding: 0 spacing(0);
      padding-left: 0;
      color: $clr-text;
      position: absolute;
      bottom: 0;
      right: 0;
      text-align: right;
      height: initial;
      width: 80%;
      z-index: 1;
    }

    .c-card__meta {
      position: absolute;
      background: rgba($clr-white,.3);
      display: flex;
      top: 0;
      right: 0;
      z-index: 1;
      svg {
        fill: $clr-white;
        height: 18px;
        margin: spacing(-2);
        width: auto;
      }
    }
    .c-card__title {
      margin-bottom: spacing(-1);
      font-size: font-size(0);
      white-space: nowrap;
      @include mq($from: xs) {
        font-size: font-size(1);
      }
      @include mq($from: sm) {
        font-size: font-size(2);
      }
    }

    svg {
      width: 30px;
      height: auto;
      fill: secondary(0);
      vertical-align: text-top;
      &:hover,
      &:focus {
        fill: $clr-black;
      }
    }

  }

  &--clickable {
    .c-card__title {
      a {
        &:before {
          @include absolute-fill;
          display: block;
          content: '';
          z-index: 1;
        }
      }
    }

    &:hover,
    &:focus {

			cursor: pointer;

      svg {
        @include transition(0);
        fill: primary(2);
      }

      a {
        // color: primary(1);
      }

      // .c-card__title,
      // .c-card__title a {
      //   @include transition(1);
      //   color: secondary(0);
      // }
    
      // .c-card__link {
      //   color: secondary(0);
      //   @include transition(1);
      // }

      .c-btn--secondary--o {
        @include transition(1);
        color: $clr-white;
        background-color: secondary(0);
        border: $border secondary(0);
      }
    }
  }

  &:after {
    content: '';
    width: 0;
  }

}
