/**
* Jumbo header with overlay
**/

.c-jumbo {
  position: relative;
  padding-top: spacing(3);
  
  &__content {
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: spacing(1) spacing(3);
  }

}
