/**
* Search component
**/

.c-search {
  @include mq($from: md) {
    margin-left: spacing(-1);
  }

  &__trigger {
    fill: $clr-text;
    background-color: transparent;
    @include transition(0);
    display: none;
    padding: 0;

    svg {
      position: relative;
      transform: none;
      top: 2px;
      width: 18px;
    }

    @include mq($from: md) {
      display: inline-block;
    }

    &:hover,
    &:focus {
      fill: primary(0);
    }
  }

  &__menu {
    background-color: grey(-2);
    position: static;
    @include transition(0);
    margin-left: -(spacing(-1));
    margin-right: -(spacing(-1));

    @include mq($from: md) {
      background-color: transparent;
      position: fixed;
      @include coords(0);
      bottom: 100%;
      z-index: z(search-base);
      @include flex-(center);
      flex-direction: row;
      overflow: hidden;
      margin: unset;
    }

    .overlay {
      position: absolute;
      @include coords(0);
      background-color: rgba(primary(0), 0);
      display: none;
      @include transition(0);

      @include mq($from: md) {
        display: block;
      }
    }

    form {
      position: relative;
      z-index: z(search, form);

      input {
        color: $clr-text;
        border: none;
        outline: none;
        @include placeholder($clr-text);
        background-color: grey(-2);
        width: calc(100% - 80px);
        padding: 22px 0 22px 20px;
        position: relative;
        top: -2px;

        @include mq($from: md) {
          color: $clr-white;
          font-size: font-size(5);
          @include placeholder($clr-white);
          line-height: 75px;
          width: unset;
          background-color: transparent;
          border-bottom: $border $clr-white;
          padding: unset;
        }
      }

      button {
        position: relative;
        top: 8px;

        @include mq($from: md) {
          position: static;
        }

        svg {
          fill: $clr-text;
          width: 30px;
          height: auto;
          @include transition(0);
          position: relative;
          top: 0;
          transform: none;

          @include mq($from: md) {
            fill: $clr-white;
            width: 60px;
          }
        }

        &:hover,
        &:focus {
          svg {
            fill: primary(0);

            @include mq($from: md) {
              fill: $clr-white;
            }
          }
        }
      }
    }
  }

  &.is-active {
    .c-search__menu {
      bottom: 0%;

      .overlay {
        background-color: rgba(primary(0), .9);
      }
    }
  }
}
