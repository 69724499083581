/**
* Header component, mobile bottom variant
* - Turn the mobile header into a fixed bottom toolbar for better ux
**/

.c-header {

  &--bottom {

    @include mq($until: $header-breakpoint) {
      position: fixed;
      bottom: 0;
      overflow: hidden;
      transition: max-height .3s ease-in-out, transform .5s ease-in-out;

      &.is-hidden {
        transform: translateY(100%);
      }

      .c-header__container {
        position: relative;
      }

      &.is-active {
        position: fixed;
        bottom: 0;
        top: auto;
        padding: 0;
        overflow-y: auto;

        .c-header__container {
          position: relative;
          justify-content: flex-end;
          padding-bottom: $header-height;
          padding-bottom: var(--header-height);
          top: 0;

          .c-header__logo {
            position: fixed;

            &::after {
              content: "";
              display: block;
              position: fixed;
              bottom: 0;
              left: 0;
              right: 0;
              top: auto;
              height: $header-height;
              height: var(--header-height);
              z-index: -1;
              transition: background-color .3s ease-in-out;
            }

          }

          .c-header__menu-toggle {
            position: fixed;
            z-index: z-index(bottom-header-toggle);
            bottom: spacing(1);
            top: auto;
          }

          .c-nav--primary {
            margin-top: 0;
          }

          .c-nav--secondary {
            order: 2;
            border-bottom: 1px solid grey(-2);
          }

        }

      }

      .c-header__logo {
        bottom: $header-toppadding;
        z-index: z-index(menu);

        &::after {
          content: "";
          display: block;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          top: calc((var(--vh, 1vh) * 100) - var(--header-height));
          background: $clr-header-bg;
          height: $header-height;
          height: var(--header-height);
          z-index: -1;
        }

      }

      .c-header__menu-toggle {
        position: absolute;
        z-index: z-index(bottom-header-toggle);
        bottom: spacing(1);
        top: spacing(-2);
        right: $header-toppadding / 2;
      }

      + .c-jumbo,
      + section {
        margin-top: 0;
      }


    }

  }

}
