/**
* Header component, fixed modifier
* - Add the fixed modifier to the header to make it ... You guessed it. (When used the header will always be fixed)
* - is-fixed can be added via header.js to make the header stick to the top after scrolling
**/

.c-header {

  &--fixed,
  &.is-fixed {
    @include transition(1);
    position: fixed;
    z-index: z-index(menu);
    left: 0;
    right: 0;

    &:not([class*=bottom]) {
      top: 0;
    }

    &.c-header--bottom {
      @include mq($from: $header-breakpoint) {
        top: 0;
      }
    }

    &.is-active {
      position: fixed;
    }

    &:not([class*=transparent]) { // Move the jumbo down if the header is not transparent

      + .c-jumbo,
      + section {
        margin-top: $header-height;
        margin-top: var(--header-height);
      }

      &.c-header--bottom {
        + .c-jumbo,
        + section {
          @include mq($until: $header-breakpoint) {
            margin-top: 0;
          }
        }
      }

    }

    &.is-scrolled { // Set via js, makes the header smaller when page is scrolled.

      @include mq($from: $header-breakpoint) {
        padding: spacing(-2) 0;

        .c-nav__link {
          color: $clr-text;
        }

        .c-nav--secondary {
          display: none;
        }

        .c-nav--primary {
          width: auto;
          background-color: transparent;
          margin-top: 0;

          &::after {
            display: none;
          }
        }

        &.c-header--alt { // Alternative header styling when scrolled & fixed

          .c-header__container {
            height: auto;
            flex-direction: row;
            align-items: center;
          }

          .c-nav--primary {
            justify-content: flex-end;
          }

        }

      }

    }
  }
}
