/**
* Social menu (often in circles)
**/

.c-social-menu {
  li {
    display: inline-block;

    a {
      display: block;
      width: 30px;
      height: 30px;
      padding: 0;
      position: relative;
      border-radius: $circle;
      @include transition(0);

      svg {
        width: auto;
        height: 14px;
        fill: $clr-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--news {
    float: right;
    @include flex-(center);

    span {
      text-transform: $text-up;
      color: $clr-text;
      font-size: font-size(-2);
      font-weight: font-weight(1);
      margin-right: spacing(-3);
    }

    li {
      a {
        background-color: secondary(0);

        &:hover,
        &:focus {
          background-color: secondary(1);
        }
      }
    }
  }

  &--footer {
    svg {
      width: 30px;
      height: 30px;
      padding: 8px;

      fill: $clr-white;
      background: primary(1);
      border-radius: 100%;
      &:hover,
      &:focus {
        fill: primary(-2);
      }
    }
  }
}

// News page
.p-newsdetail {
  .c-social-menu--news { @extend .c-social-menu; }
}

// Footer components
.c-footer {
  .c-social-menu--footer { @extend .c-social-menu; }
}
