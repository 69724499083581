/**
* Base type elements
**/

p {
  color: $clr-text;
  margin-top: 0;
  margin-bottom: spacing(0);
}

b,
strong {
  font-weight: font-weight(1);
}



/**
* Base link
**/

a {
  color: primary(0);
  text-decoration: none;
  @include transition(-1);

  &:hover,
  &:focus {
    color: primary(1);
    text-decoration: none;
  }
}



/**
* Base img tag
**/

img {
  max-width: 100%;
  vertical-align: middle;
}



/**
* Base list
**/

li.is-active > a,
li > a.is-active {
  color: primary(1);
}



/**
* Base form elements
**/

input,
textarea {
  color: $clr-text;
  font-family: $body-font;
}

button {
  cursor: pointer;
  font-family: $body-font;
}



/**
* Address element
**/

address {
  font-style: normal;
}



/**
* Time element
**/

time {
  font-size: font-size(-2);
  font-weight: font-weight(1);
  display: block;
  margin-bottom: spacing(0);
  color: primary(0);
}



/**
* Custom selection color
**/

::-moz-selection,
::selection {
  color: $clr-text;
  background: primary(-2);
}
