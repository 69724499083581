/**
* Z-index
* Layered per nested item
* Lowest start at the top
* Debug should always be defined as last item
**/

$z-index: (
  form: (
    file
  ),
  jumbo: (
    image,
    overlay,
    content
  ),
  language: (),
  children: (),
  top: (),
  menu: (
    mobile,
    top-nav,
  ),
  bottom-header-toggle: (),
  collapse: (),
  cookie: (),
  search-base: (),
  search: (
    form
  ),
  modal: (
    backdrop,
    close,
    content
  ),
  alert: (),
  cookie-modal: (),
  debug: ()
);
