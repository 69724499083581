/**
* Newsletter
**/

.c-newsletter {
  background-color: primary(0);
  padding: spacing(2) 0;
  margin-bottom: spacing(2);

  h3 {
    color: $clr-white;
  }

  &__form-wrapper {
    position: relative;
  }

  &__form {
    .c-form__group {
      margin-bottom: 0;
    }
  }

  &__form-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: secondary(0);
    border: none;
    border-top-right-radius: $corners;
    border-bottom-right-radius: $corners;
    padding: 0 spacing(0);
    transition: transition(0);

    svg {
      fill: $clr-white;
      width: 9px;
      height: 14px;
    }

    &:hover,
    &:focus {
      background: secondary(1);
    }
  }
}
