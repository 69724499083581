/**
* Base brand/website colors
**/

$clr-primary:   #4ca6D6;  // Your global primary color
$clr-secondary: #f19114;  // Your global secondary color
$clr-third:     #93C124;  
$clr-grey:      #797979;  // Define default grey
$clr-black:     #333333;  // Default dark color
$clr-white:     #FFFFFF;  // Default white color
$clr-text:      #433C3D;  // Default text color


/**
* General colors
**/

$clr-confirm: #21A74F;  // Form validation - positive
$clr-error:   #FF374F;  // Form validation - negative
$clr-warning: #D8C250;  // General warning color
$clr-message: #007BFF;  // General neutral message color
$clr-debug:   #0FF0FF;  // Baseline grid color

// color: #f19114 #93C124

/**
* Color variations map
* => New color?
* Remember to create a function in 1_tools/_functions.scss to be able to use "newcolor(variation);" anywhere else in the scss
**/

$colors: (
  primary: (
    -3: lighten($clr-primary, 42%),
    -2: lighten($clr-primary, 25%),
    -1: lighten($clr-primary, 15%),
    0:  $clr-primary,
    1:  darken($clr-primary, 15%),
    2:  darken($clr-primary, 25%)
  ),
  secondary: (
    -3: lighten($clr-secondary, 47%),
    -2: lighten($clr-secondary, 20%),
    -1: lighten($clr-secondary, 15%),
    0:  $clr-secondary,
    1:  darken($clr-secondary, 15%)
  ),
  third: (
    -1: lighten($clr-third, 15%),
    0:  $clr-third,
    1:  darken($clr-third, 15%)
  ),
  grey: (
    -3: #F9F9F9,
    -2: #EDEDEE,
    -1: lighten($clr-grey, 15%),
    0:  $clr-grey,
    1:  darken($clr-grey, 15%),
    2:  darken($clr-grey, 25%)
  ),
  confirm: (
    -2: lighten($clr-confirm, 45%),
    -1: lighten($clr-confirm, 20%),
    0:  $clr-confirm
  ),
  error: (
    -3: lighten($clr-error, 45%),
    -2: lighten($clr-error, 30%),
    -1: lighten($clr-error, 20%),
    0:  $clr-error
  ),
  warning: (
    -2: lighten($clr-warning, 35%),
    -1: lighten($clr-warning, 20%),
    0:  $clr-warning
  ),
  message: (
    -2: lighten($clr-message, 45%),
    -1: lighten($clr-message, 20%),
    0:  $clr-message
  )
);



/**
* Shadows
**/

$shadow-size: 25px;
$shadow-x:    0;
$shadow-y:    0;
$shadow: (
  -1:         $shadow-x $shadow-y $shadow-size rgba($clr-grey, .3),
  0:          $shadow-x $shadow-y $shadow-size rgba($clr-grey, .6),
  1:          $shadow-x $shadow-y $shadow-size rgba($clr-grey, 1)
);
