/**
* Pagination
**/

.c-page-navigation {
  margin: spacing(0) auto;
}

.c-pagination {
  @include flex-(center);
  flex-wrap: wrap;

  &__link {
    color: $clr-text;
    display: inline-block;
    width: auto;
    height: 33px;
    line-height: 33px;
    text-align: center;
    font-size: font-size(-1);
    font-weight: font-weight(-1);
    padding: 0 10px;
    border: $border grey(-1);
    @include transition(0);
    cursor: pointer;

    &:hover {
      color: primary(0);
      border: $border primary(0);
    }
  }

  &__item {
    margin: 0 2px spacing(-3);

    &--prev,
    &--next {
      @extend .c-pagination__item;

      img {
        position: relative;
        top: -2px;
      }
    }

    &.is-active {
      .c-pagination__link {
        color: $clr-white;
        background: primary(0);
        border: $border primary(0);
      }
    }

    &.is-disabled {
      opacity: .6;
      cursor: not-allowed;

      .c-pagination__link {
        border: $border grey(-1);
        background-color: transparent;
        cursor: not-allowed;

        &:hover,
        &:focus {
          color: $clr-text;
        }
      }
    }
  }
}



/**
* News navigation
**/

.news-navigation {
  margin: spacing(2) auto spacing(0);

  @include mq($from: sm) {
    display: flex;
    align-items: stretch;
  }

  a {
    background-color: grey(-2);
    position: relative;
    flex: 1 0 50%;
    display: flex;
    align-items: center;
    @include transition(1);

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: $clr-text;
      width: 14px;
      height: auto;
      @include transition(1);
    }

    &:hover,
    &:focus {
      background-color: $clr-white;
      box-shadow: shadow(-1);

      svg {
        fill: primary(0);
        width: 20px;
      }
    }
  }

  &--prev,
  &--next {
    border-bottom: 3px solid $clr-white;

    @include mq($from: sm) {
      border-bottom: none;
    }
  }

  &--prev {
    padding: spacing(0) spacing(0) spacing(0) spacing(1);

    @include mq($from: sm) {
      border-right: 3px solid $clr-white;
    }

    svg {
      left: spacing(0) - 5;
    }

    &:hover,
    &:focus {
      svg {
        left: 5px;
      }
    }
  }

  &--next {
    padding: spacing(0) spacing(1) spacing(0) spacing(0);

    @include mq($from: sm) {
      border-top: none;
      border-left: 3px solid $clr-white;
    }

    svg {
      right: spacing(0) - 5;
    }

    &:hover,
    &:focus {
      svg {
        right: 5px;
      }
    }
  }
}
