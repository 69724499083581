$sizes: "-xxs", "-xs", "-s", "", "-l", "-xl", "-xxl", "-3xl";

$attributes: "margin", "padding";

$directions: "top", "bottom", "left", "right", "y", "x";

/* stylelint-disable declaration-no-important  */

/**
* Margins
**/

.u-margin {
  margin: spacing(0);

  &-none { margin: 0 !important; }
  &-top-none { margin-top: 0 !important; }
  &-bottom-none { margin-bottom: 0 !important; }
  &-left-none { margin-left: 0 !important; }
  &-right-none { margin-right: 0 !important; }
  &-ver-none { margin-top: 0 !important; margin-bottom: 0 !important; }
  &-hor-none { margin-left: 0 !important; margin-right: 0 !important; }

  @each $size in $sizes {
    $i: index($sizes, $size) - 4;

    &-top#{$size} { margin-top: spacing($i); }
    &-bottom#{$size} { margin-bottom: spacing($i); }
    &-left#{$size} { margin-left: spacing($i); }
    &-right#{$size} { margin-right: spacing($i); }
    &-vertical#{$size} { margin-top: spacing($i); margin-bottom: spacing($i); }
    &-horizontal#{$size} { margin-left: spacing($i); margin-right: spacing($i); }
  }

  @each $mq, $mq-value in $mq-breakpoints {
    @include mq($from: $mq) {
      &-#{$mq}-none { margin: 0 !important; }
      &-#{$mq}-top-none { margin-top: 0 !important; }
      &-#{$mq}-bottom-none { margin-bottom: 0 !important; }
      &-#{$mq}-left-none { margin-left: 0 !important; }
      &-#{$mq}-right-none { margin-right: 0 !important; }
      &-#{$mq}-ver-none { margin-top: 0 !important; margin-bottom: 0 !important; }
      &-#{$mq}-hor-none { margin-left: 0 !important; margin-right: 0 !important; }

      @each $size in $sizes {
        $i: index($sizes, $size) - 4;

        &-#{$mq}-top#{$size} { margin-top: spacing($i) !important; }
        &-#{$mq}-bottom#{$size} { margin-bottom: spacing($i) !important; }
        &-#{$mq}-left#{$size} { margin-left: spacing($i) !important; }
        &-#{$mq}-right#{$size} { margin-right: spacing($i) !important; }
        &-#{$mq}-ver#{$size} { margin-top: spacing($i) !important; margin-bottom: spacing($i) !important; }
        &-#{$mq}-hor#{$size} { margin-left: spacing($i) !important; margin-right: spacing($i) !important; }
      }
    }
  }
}



/**
* Padding
**/

.u-padding {
  padding: spacing(0);

  &-none { padding: 0; }

  @each $size in $sizes {
    $i: index($sizes, $size) - 4;

    &-top#{$size} { padding-top: spacing($i); }
    &-bottom#{$size} { padding-bottom: spacing($i); }
    &-left#{$size} { padding-left: spacing($i); }
    &-right#{$size} { padding-right: spacing($i); }
    &-vertical#{$size} { padding-top: spacing($i); padding-bottom: spacing($i); }
    &-horizontal#{$size} { padding-left: spacing($i); padding-right: spacing($i); }
  }

  @each $mq, $mq-value in $mq-breakpoints {
    @include mq($from: $mq) {
      &-#{$mq}-none { padding: 0; }
      &-#{$mq}-top-none { padding-top: 0 !important; }
      &-#{$mq}-bottom-none { padding-bottom: 0 !important; }
      &-#{$mq}-left-none { padding-left: 0 !important; }
      &-#{$mq}-right-none { padding-right: 0 !important; }
      &-#{$mq}-ver-none { padding-top: 0 !important; padding-bottom: 0 !important; }
      &-#{$mq}-hor-none { padding-left: 0 !important; padding-right: 0 !important; }

      @each $size in $sizes {
        $i: index($sizes, $size) - 4;

        &-#{$mq}-top#{$size} { padding-top: spacing($i) !important; }
        &-#{$mq}-bottom#{$size} { padding-bottom: spacing($i) !important; }
        &-#{$mq}-left#{$size} { padding-left: spacing($i) !important; }
        &-#{$mq}-right#{$size} { padding-right: spacing($i) !important; }
        &-#{$mq}-ver#{$size} { padding-top: spacing($i) !important; padding-bottom: spacing($i) !important; }
        &-#{$mq}-hor#{$size} { padding-left: spacing($i) !important; padding-right: spacing($i) !important; }
      }
    }
  }
}

/* stylelint-enable */
