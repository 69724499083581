/**
* Input placeholder
**/

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}



/**
* Clearfix
* Use flow-root in modern browsers https://caniuse.com/#feat=flow-root
* Usage: @extend %clearfix;
**/

%clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @supports (display: flow-root) {
    display: flow-root;
    &::after {
      display: none;
    }
  }
}



/**
* Inline svg
* Usage: @extend %inline-svg;
**/

%inline-svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: inherit;
  overflow: visible;
  vertical-align: -.125em;
}



/**
* Grayscale
* Usage: @extend %grayscale;
**/

%grayscale {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android**/
  filter: gray; /* IE6-9**/
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS**/
}

%grayscale_disable {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  -webkit-filter: grayscale(0%);
}



/**
* Flex center
**/

@mixin flex-($pos) {
  display: flex;
  align-items: $pos;
  justify-content: $pos;
}



/**
* Reset coords to 0 when positioning
**/

@mixin coords($reset) {
  top: $reset;
  left: $reset;
  right: $reset;
  bottom: $reset;
}



/**
* absolute positioning for pseudo elements
**/

@mixin absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}



/**
* Error states
**/

@mixin error($color, $bg-color) {
  background-color: $bg-color;
  color: $color;
  border-radius: $corners;
  padding: spacing(0);
  font-size: font-size(-1);
  line-height: line-height(0);
  text-transform: $text-none;
  position: absolute;
  bottom: 120%;
  left: 0;
  display: none;
}

@mixin error-holder($color) {
  border: 5px dashed $color;
  position: relative;

  &:hover { &::after { display: block; }}
}



/**
* Transition
* Usage: @include transition(0);
**/

@mixin transition($speed) {
  transition: transition($speed);
}



/**
* Generate utility classes based on map
**/

@mixin modifiers($map, $attribute, $prefix: "--", $separator: "", $base: 0) {
  @each $key, $value in $map {
    &#{if($key != $base, #{$prefix}#{$key}, "")} {
      @if type-of($value) == "map" {             // generate utility class with provided values
        @include modifiers($value, $attribute, $separator);
      }
      @else if type-of($attribute) == "list" {  // generate utility class with multiple attributes set to same value
        @each $a in $attribute {                // e.g.: @include modifiers($map: $spacing-map, $attribute: ('padding-left', 'padding-right'), $prefix: '--');
          #{$a}: $value;
        }
      }
      @else {
        #{$attribute}: $value;
      }
    }
  }
}



/**
* Fluid font-size mixin
* Usage eg.: @include fluid-type(breakpoint(xs), breakpoint(xl), font-size(2), font-size(5));
**/

@function convert-unit($target){  // Convert all units to px
  $one:1;
  @if not unitless($target){
    //find out the unit of measurement being used
    @if (unit($target) == "rem"){
      $one:1rem;
      @return (($target / $one) * 10) * 1px ; // dividing by the same unit forces sass to return a value with no unit
    }
    @else if (unit($target) == "em"){
      $one:1em;
      @return (($target / $one) * 10) * 1px ; // dividing by the same unit forces sass to return a value with no unit
    }
    @else if (unit($target) == "px"){
      $one:1px;
      @return $target;
    }
  }
  @else {
    @return $target;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $min-font: convert-unit($min-font-size);
  $max-font: convert-unit($max-font-size);
  $min-vw: convert-unit($min-vw);
  $max-vw: convert-unit($max-vw);
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font);
  $u4: unit($max-font);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font} + #{strip-unit($max-font - $min-font)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
  @else {
    font-size: $u1, $u2, $u3, $u4, $min-font, $max-font, $min-vw, $max-vw;
  }
}
