/**
* FAQ-item pagepart
**/

.pp-faq {
  border-bottom: $border grey(-2);

  &__title {
    cursor: pointer;
    position: relative;
    margin-bottom: 5px;
    padding-right: spacing(1);
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    text-align: right;

    svg {
      fill: primary(0);
      width: 13px;
      height: auto;
      transform: rotate(0deg);
      @include transition(0);
    }
  }

  &__content {
    padding: 0 0 0 spacing(0);
    position: relative;

    p {
      margin-top: 0;
    }

    svg {
      position: absolute;
      top: 17px;
      left: 0;
      width: 11px;
      height: auto;
    }
  }

  .is-active {
    &.pp-faq__content {
      padding: spacing(-2) 0 spacing(-2) spacing(0);
    }

    .pp-faq__icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  & + .pp-faq {
    margin-top: -(spacing(-3));

    @include mq($from: md) {
      margin-top: -(spacing(-1));
    }
  }
}
