/**
* Team pagepart
**/

.pp-team {
  .o-row {
    display: block;

    div {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mq($from: xs) {
      display: flex;
    }
  }

  &__member {
    margin-bottom: spacing(0);
    position: relative;
    box-shadow: shadow(-1);
    overflow: hidden;

    img {
      width: 100%;
    }

    video,
    .embed-responsive {
      width: 100%;
    }

    &:hover {
      .pp-team__content {
        a {
          right: 15px;
          transform: rotate(0deg);
        }
      }
    }
  }

  &__content {
    padding: spacing(0);

    strong {
      display: block;
    }

    em {
      display: block;
      color: primary(0);
      margin-bottom: spacing(-3);
    }

    a {
      position: absolute;
      top: 15px;
      right: -65px;
      background-color: primary(0);
      @include flex-(center);
      width: 30px;
      height: 30px;
      border-radius: $circle;
      transform: rotate(-160deg);
      @include transition(0);

      svg {
        fill: $clr-white;
      }

      &:hover {
        background-color: secondary(0);
      }

      &:nth-of-type(2) {
        top: 55px;
      }
    }
  }
}
