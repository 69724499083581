/**
* Header component, Alternative layout
* - Add the alt modifier to the header to align both menu's to the right of the logo on desktop.
* - Make sure the c-header__nav is present around the navigation
**/

.c-header {

  background: secondary(0);

  &__nav { // Navigation container to make menu's align to the right of the logo
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }

  &--alt {

    @include mq($from: $header-breakpoint) { // Only use the alt styling on desktop
      padding: $header-toppadding 0;

      .c-header__container {
        flex-wrap: nowrap;
        flex-direction: row;

        > [class*=c-nav] {
          flex-grow: 1;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .c-header__logo {
        height: auto;
      }

      .c-nav--primary {
        background: transparent;
        width: auto;
        padding: 0;
        justify-content: flex-end;

        &::after {
          display: none;
        }

        .c-nav__item--search {
          margin-left: spacing(0);
        }
      }
    }
  }
}
