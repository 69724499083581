/**
* Alerts component
**/

.c-intro {
  // font-size: font-size(1);
  p {
  	// color: $clr-white;
  	font-size: font-size(1);
  	&:last-child {
  		margin-bottom: 0;
  	}
  }
}
