/**
* Reason pagepart
**/

.pp-reason {
  padding: spacing(3) 0;

  h2 {
    color: primary(0);
    margin-top: 0;
  }

  ul {
    li {
      position: relative;
      padding-left: spacing(1);
      margin-bottom: spacing(-1);
      width: 600px;
      max-width: 100%;

      svg {
        position: absolute;
        top: 5px;
        left: 0;
        fill: primary(0);
        width: 15px;
        height: auto;
      }
    }
  }

  &--home {
    background-color: primary(-2);
  }
}

// Home page
.p-home {
  .pp-reason { @extend .pp-reason--home; }
}
