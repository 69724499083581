/**
* Gallery pagepart
**/

.pp-gallery {
  &__image {
    box-shadow: shadow(-1);
    @include transition(0);
    margin-bottom: spacing(-1);
    overflow: hidden;

    @include mq($from: md) {
      margin-bottom: spacing(0);
    }

    img {
      transform: scale(1.06);
      @include transition(0);
    }

    &:hover {
      box-shadow: shadow(0);

      img {
        transform: scale(1);
      }
    }
  }

  video,
  .embed-responsive {
    width: 100%;
  }
}
