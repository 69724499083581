/**
* Sidebar
**/

.c-sidebar {
  background-color: grey(-2);
  padding: spacing(0);
  margin-bottom: spacing(0);

  @include mq($from: md) {
    padding: spacing(1);
  }

  li {
    &:not(:last-child) {
      margin-bottom: spacing(0) / 2;
    }

    a {
      color: $clr-text;
      @include transition(0);

      svg {
        fill: $clr-text;
        @include transition(0)
      }

      &:hover,
      &:active,
      &.is-active {
        color: primary(0);

        svg {
          fill: primary(0);
        }
      }
    }

    ul {
      margin: (spacing(0) / 2) 0 spacing(0) spacing(0);
    }
  }

  &.is-active {
    position: fixed;
    top: 30px;
    bottom: auto;
  }
}



/**
* Social sidebar
**/

.c-social-sidebar {
  li {
    padding-left: spacing(1);
    position: relative;

    span,
    address {
      display: inline-block;
      margin-bottom: spacing(0);
    }

    svg {
      position: absolute;
      left: 0;
      fill: primary(0);
    }
  }

  &--contact {
    background-color: grey(-2);
    padding: 30px;

    li {
      line-height: line-height(1);

      &:nth-of-type(1) { svg { top: 9px; }}
      &:nth-of-type(2) { svg { top: 8px; }}
      &:nth-of-type(3) { svg { top: 12px; }}
    }
  }

  &--locations {
    margin-bottom: spacing(-1);

    @include mq($from: md) {
      margin-bottom: spacing(0);
    }

    li {
      line-height: 25px;

      svg {
        top: 6px;
        width: 14px;
        height: auto;
        max-height: 17px;
      }
    }
  }

  &--footer {
    background-color: transparent;
    padding: 0;

    li {
      line-height: 25px;

      &:nth-of-type(1) { svg { top: 10px; }}
      &:nth-of-type(2) { svg { top: 9px; }}
      &:nth-of-type(3) { svg { top: 13px; }}
    }
  }
}

// Contact page
.p-contact {
  .c-social-sidebar--contact { @extend .c-social-sidebar; }
}

// Locations page
.p-location {
  .c-social-sidebar--locations { @extend .c-social-sidebar; }
}

// Footer component
.c-footer {
  .c-social-sidebar--footer { @extend .c-social-sidebar; }
}
