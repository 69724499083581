/**
* Image and text pagepart
**/

.pp-image-text {
  h2 {
    margin-top: 0;
  }

  .o-row {
    display: block;

    aside {
      position: relative;
      max-width: 100%;
      margin-bottom: spacing(0);
    }

    article {
      max-width: 100%;
    }

    video,
    .embed-responsive {
      width: 100%;
    }

    @include mq($from: xs) {
      display: flex;
    }
  }

  .flex-last { // Position image right
    @include mq($from: xs) {
      order: 1;
    }
  }
}
