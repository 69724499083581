/**
* Collapse component
**/

.c-collapse {
  max-height: 0;
  overflow: hidden;
  @include transition(0);

  &.is-active {
    max-height: 300px;
    @include transition(1);
  }
}



/**
* Disable all children for dropdown trigger
**/

[data-toggle=dropdown] > * {
  pointer-events: none;
}
