/**
* Top navigation
**/

.c-top-nav {
  position: relative;
  z-index: z(top);
  background-color: primary(-2);

  .o-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}