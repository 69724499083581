/**
* Flexbox grid styles
**/

$grid-rhythm: spacing(0);

.o-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -(spacing(0)) / 2;
  margin-left: -(spacing(0)) / 2;
  margin-top: -$grid-rhythm;

   > * {
    margin-top: $grid-rhythm * 1.5;
    @include mq($from: sm) {
      margin-top: $grid-rhythm;    
    }
  }

  &--reverse {
    @extend .o-row;
    flex-direction: row-reverse;
  }

  &--flush {
    @extend .o-row;
    margin-right: 0;
    margin-left: 0;

    > [class^=o-col] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &--sm {
    @extend .o-row;

    @include mq($from: sm) {
      margin-right: -(spacing(-1)) / 2;
      margin-left: -(spacing(-1)) / 2;

      > [class^=o-col] {
        padding-right: spacing(-1) / 2;
        padding-left: spacing(-1) / 2;
      }
    }
  }

  &--lg {
    @extend .o-row;

    @include mq($from: sm) {
      margin-right: -(spacing(1)) / 2;
      margin-left: -(spacing(1)) / 2;

      > [class^=o-col] {
        padding-right: spacing(1) / 2;
        padding-left: spacing(1) / 2;
      }
    }
  }

  &--xl {
    @extend .o-row;

    @include mq($from: sm) {
      margin-right: -(spacing(2)) / 2;
      margin-left: -(spacing(2)) / 2;

      > [class^=o-col] {
        padding-right: spacing(2) / 2;
        padding-left: spacing(2) / 2;
      }
    }
  }
}

[class*=o-col-] {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: spacing(0) / 2;
  padding-left: spacing(0) / 2;
  max-width: 100%;
  margin-top: spacing(0);
}

@for $i from 0 through 12 {
  .o-col-#{$i} {
    flex-basis: calc(#{$i} / 12 * 100%);
    max-width: calc(#{$i} / 12 * 100%);
  }

  .o-col-offset-#{$i} {
    margin-left: calc(#{$i} / 12 * 100%);
  }
}

@each $mq, $mq-value in $mq-breakpoints {
  @include mq($from: $mq) {
    @for $i from 0 through 12 {
      .o-col-#{$mq}-#{$i} {
        flex-basis: calc(#{$i} / 12 * 100%);
        max-width: calc(#{$i} / 12 * 100%);
      }

      .o-col-#{$mq}-offset-#{$i} {
        margin-left: calc(#{$i} / 12 * 100%);
      }
    }
  }
}
