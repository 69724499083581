/**
* Container
**/

$gutter : spacing(0) / 2;

.o-container {
  @extend %clearfix;
  padding-right: $gutter;
  padding-left: $gutter;
  margin-right: auto;
  margin-left: auto;
  width: $container-width;
  max-width: 100%;
}
