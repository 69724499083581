/**
* Homepage specific styling
**/

.p-home {
  .home-block {
    margin: spacing(2) auto;

    @include mq($from: md) {
      margin: spacing(4) auto;
    }

    h2 {
      color: primary(0);
      margin-top: 0;
    }

    &--news {
      &__item {
        margin-bottom: spacing(1);

        @include mq($from: sm) {
          margin-bottom: 0;
        }

        h3 {
          margin: spacing(-1) auto;
        }

        img {
          width: 100%;
          margin-bottom: spacing(0);
        }

        time {
          margin-bottom: spacing(-1);

          svg {
            width: 12px;
            height: auto;
            margin-right: spacing(-2);
            margin-top: -2px;
          }
        }
      }

      .o-col-xs-12:not(:last-child) {
        .home-block--news__item {
          padding-bottom: spacing(1);
          border-bottom: $border grey(-2);

          @include mq($from: sm) {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
}
