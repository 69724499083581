/**
* Filters component
**/

.c-filters {
  @include flex-(center);
  flex-wrap: wrap;
  margin: spacing(0) 0;

  @include mq($from: md) {
    margin: spacing(1) 0;
  }

  a {
    color: $clr-text;
    border: $border grey(-1);
    border-radius: spacing(1);
    padding: $btn-padding;
    margin: 0 spacing(-3) spacing(-2);

    &:hover {
      border: $border primary(0);
    }

    &.is-active {
      color: $clr-white;
      background-color: primary(0);
      border: $border primary(0);
    }
  }
}
