/**
* Download pagepart
**/

.pp-download {
  background-color: grey(-2);

  a {
    display: block;
    padding: 15px spacing(0);

    @include mq($from: xs) {
      @include flex-(center);
      padding: 5px spacing(0);
    }

    @include mq($from: md) {
      padding: 10px spacing(1);
    }

    span {
      flex: 1 0 auto;
    }

    button {
      display: inline-block;
      margin-top: spacing(0);

      @include mq($from: xs) {
        margin-top: 0;
      }

      svg {
        width: auto;
        height: 22px;
        margin-left: spacing(0) / 2;
        fill: $clr-white;
      }
    }
  }
}
