/**
* Dropzone component
**/

.c-dropzone-holder {
  .is-hidden { // Dropzone holder with file list - select
    display: none;
  }
}

.c-dropzone {
  min-height: 200px;
  background: grey(-3);
  padding: spacing(-1);
  border-radius: $corners;
  border: 1px dashed grey(-1);
  transition: transition(0);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .dz-message {
    text-align: center;
    margin: spacing(0) 0;
    cursor: pointer;
    color: grey(0);
    transition: transition(0);
    position: absolute;
    top: calc(50% - #{spacing(0) / 2});
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      position: relative;
      padding-left: 30px;
      opacity: .3;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url("../images/general/paperclip.svg") no-repeat left center;
        background-size: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .dz-preview {
    position: relative;
    vertical-align: top;
    margin: spacing(-3);
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(33.333% - 8px);

    &::after {
      content: "";
      background: url("../images/general/preloader.svg") no-repeat center;
      background-size: contain;
      display: block;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 42px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 200;
    }

    .dz-details {
      z-index: 20;
      font-size: font-size(-1);
      padding-top: spacing(-2);
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      color: $clr-black;
      line-height: 150%;

      .dz-size {
        display: none;
      }

      .dz-filename {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        max-height: 42px;
        display: block;
        line-height: 20px;
      }
    }

    .dz-success-mark,
    .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 47px;
      left: 50%;
      margin-left: -12px;
      margin-top: -12px;

      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }

    .dz-error-mark {
      svg g {
        fill: error(0);
      }
    }

    .dz-remove {
      cursor: pointer;
      z-index: 100;
      text-align: center;
      font-size: font-size(-2);

      &:hover {
        color: secondary(0);
      }
    }

    &:hover {
      z-index: 1000;
    }

    &.dz-error {
      .dz-error-mark {
        opacity: 1;
      }

      .dz-error-message {
        display: block;
      }

      &:hover {
        .dz-error-message {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &.dz-complete {
      &::after {
        display: none;
      }
    }
  }

  .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: none;
    opacity: 0;
    border-radius: 4px;
    font-size: font-size(-1);
    width: 100%;
    background: $clr-error;
    padding: 5px 10px;
    text-align: center;
    color: $clr-white;
    transform: translateY(-55%);

    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $clr-error;
      transform: translateX(-50%);
    }
  }

  .dz-progress {
    display: none;
  }

  &.dz-clickable {
    cursor: pointer;

    * {
      cursor: default;
    }

    .dz-message {
      cursor: pointer;

      * {
        cursor: pointer;
      }
    }
  }

  &.dz-started {
    .dz-message {
      display: none;
    }
  }

  &.dz-drag-hover {
    border: $border primary(0);

    .dz-message {
      opacity: .5;
    }
  }

  &:hover,
  &:focus {
    border: 1px dashed primary(0);

    .dz-message {
      color: $clr-text;

      span {
        opacity: .7;
      }
    }
  }

  .dz-image {
    width: 100%;
    padding-top: 75%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

  }

  &--single {
    @extend .c-dropzone;

    .dz-preview {
      text-align: center;

      .dz-image {
        overflow: hidden;
        position: relative;
        display: block;
        z-index: 10;

        img {
          object-fit: cover;
          object-position: center;
          font-family: "object-fit: cover; object-position: center;";
        }
      }
    }
  }

  &--multiple {
    @extend .c-dropzone;

    .dz-preview {

      .dz-image {
        overflow: hidden;
        position: relative;
        display: inline-block;
        z-index: 10;

        img {
          display: block;
          object-fit: cover;
          object-position: center;
          font-family: "object-fit: cover; object-position: center;";
        }
      }
    }
  }
}
