/**
* Global variables
**/

$container-width: 1400px;     // Main container max width
$circle:          50%;        // Border radius value to create circle shape
$corners:         8px;        // Default border radius
$overlay:         .4;         // Define level of opacity for color overlay
$border:          1px solid;  // Default border width and type
$header-height:   144px;       // The max height for non-active header. Mainly for IE11 css variables backup.
$jumbo-height:    70vh;       // The height of the (homepage) jumbo


/**
* Breakpoints
**/

$mq-breakpoints: (
  xs: 30em, // 480px
  sm: 48em, // 768px
  md: 64em, // 1024px
  lg: 75em, // 1200px
  xl: 100em // 1600px
) !default;

@import "~sass-mq/mq";



/**
* Buttons
**/

$btn-corners: 4px;
$btn-padding: 14px 20px;



/**
* Transitions
**/

$transition: (
  -2: all 1s,
  -1: all .6s,
  0:  all .3s,
  1:  all .2s
);
