/**
* Page preview
**/

.c-page-preview {
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: column;

  @include mq($until: md) {
    margin-bottom: spacing(2);
  }

  &__holder {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    cursor: pointer;
  }

  &__image,
  video,
  .embed-responsive {
    display: block;
    width: 100%;
    margin-bottom: spacing(0);
  }

  &__content {
    time {
      font-size: font-size(-2);
      font-weight: font-weight(1);
      display: block;
      margin-bottom: spacing(0);
    }

    h2 {
      margin: 0 0 spacing(0) 0;
    }
  }

  &__link {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;

    .btn {
      svg {
        width: 12px;
        height: auto;
        fill: primary(0);
        margin-left: 0;
        @include transition(0);
      }
    }

    &:hover {
      .btn {
        svg {
          margin-left: spacing(-2);
        }
      }
    }
  }
}



/**
* Search preview
**/

.c-search-preview {
  margin-bottom: spacing(0);
  padding-bottom: spacing(0);

  @include mq($until: md) {
    margin-bottom: spacing(1);
    padding-bottom: spacing(1);
  }

  &:not(:last-of-type) {
    border-bottom: $border grey(-2);
  }

  &__holder {
    display: block;
    flex-direction: row;
    flex: 1 0 auto;
    flex-wrap: wrap;

    @include mq($from: sm) {
      display: flex;
    }
  }

  &__media {
    flex: 1 0 240px;
    max-width: 240px;
    margin-right: spacing(0);
    margin-bottom: spacing(0);

    @include mq($from: sm) {
      margin-bottom: unset;
    }

    video,
    .embed-responsive {
      width: 100%;
    }
  }

  &__content {
    @include mq($from: sm) {
      flex: 1 0 calc(100% - 264px);
      max-width: calc(100% - 264px);
    }

    h2 {
      margin: 0 0 spacing(0) 0;
    }
  }

  .btn {
    display: inline-block;
  }
}

.c-search--asset {
  .c-search-preview__media {
    display: none;

    @include mq($from: sm) {
      display: block;
    }
  }

  .c-search-preview__content {
    flex: 1 0 100%;

    @include mq($from: sm) {
      flex: 1 0 calc(100% - 260px);
      padding-left: spacing(0);
    }

    @include mq($from: md) {
      padding-left: spacing(1);
    }
  }
}
