/**
* Back to top button
**/

.c-back-to-top {
  background: primary(0);
  position: fixed;
  bottom: spacing(2);
  right: spacing(0) / 2;
  height: 35px;
  width: 35px;
  z-index: z(top);
  cursor: pointer;
  display: none;
  border: none;
  border-radius: $circle;
  @include transition(0);

  svg {
    position: relative;
    top: -3px;
    transform: scale(1.5);
    fill: $clr-white;
    @include transition(0);
  }

  &:hover,
  &:focus {
    background: transparent;

    svg {
      transform: scale(3);
      fill: primary(0);
    }
  }

  &.is-active {
    display: block;
  }
}

.has-bottom-header {
  .c-back-to-top {
    display: none;
  }
}
