/**
* Line pagepart
**/

.pp-line {
  border-top: $border grey(-2);
  padding-bottom: 1px;

  hr {
    display: none;
  }
}
