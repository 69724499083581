/**
* Let item make a complete 360
**/

@keyframes circleLoop {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/**
* Expand selected item to larger size
**/

@keyframes expand
{
  0% {
    width: 2px;
    height: 2px;
    top: 24px;
    left: 24px;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    opacity: .2;
  }
}



/**
* Ken Burns effect
**/

@keyframes kenburns {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    transform: scale3d(1.5, 1.5, 1.5) translate3d(-190px, -120px, 0);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  100% {
    transform: scale3d(2, 2, 2) translate3d(-170px, -100px, 0);
    opacity: 0;
  }
}
