/**
* Background colors
**/

.u-bg {
  background-color: primary(0);
  @include modifiers($colors, "background-color");
}



/**
* Font colors
**/

.u-color {
  color: primary(0);
  @include modifiers($colors, "color");
}



/**
* Border colors
**/

[class*=u-border] {
  border: $border;
}

.u-border {
  border-color: inherit;

  &--less {
    border: none;
  }

  @include modifiers($colors, "border-color");
}
