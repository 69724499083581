/**
* Main navigation
**/

.c-nav {

  &--primary {
    display: none; //don't show by default on mobile
    margin-top: spacing(0);
    background: primary(0); // Primary nav container background, can be removed if using the full width background
    position: relative;
    transition: width .3s ease-in-out, margin-top .3s ease-in-out, padding .3s ease-in-out;
    order: 2;

    &::after { // Primary full width nav bar background
      content: "";
      background: primary(0);
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      // Pull background color to left and right window edge
      left: calc((-100vw - #{$container-width}) / 2);
      right: calc((-100vw - #{$container-width}) / 2);

      @include mq($until: $header-breakpoint) {
        left: -#{spacing(0) / 2}; // Make the background color span width of container plus outer grid margin
        right: -#{spacing(0) / 2}; // Make the background color span width of container plus outer grid margin
      }

    }

    .c-nav__item {
      display: flex;
      align-items: center;
      z-index: 1;
      padding: spacing(-2) 0;
    }

    .c-nav__item--search {
      margin-left: auto;
      flex: 0 1 20%;
      justify-content: flex-end;
      min-width: spacing(3) * 2;
    }

    .c-nav__link {
      color: $clr-white;
      font-size: font-size(1);
      font-weight: font-weight(2);
      border-bottom: 2px solid transparent;
      letter-spacing: -0.025em;

      &.is-active,
      &:focus,
      &:hover {
        border-bottom: 2px solid $clr-white;
      }
    }

    @include mq($from: $header-breakpoint) {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

  }

  &--secondary {
    display: none; //don't show by default on mobile
    flex-direction: row;
    order: 1;
    margin-top: spacing(1);

    .c-nav__items {
      align-items: center;
    }

    .c-nav__item--logo {
      margin-right: auto;
    }

    .c-nav__link {
      color: $clr-white;
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    @include mq($from: $header-breakpoint) {
      display: flex;
      margin-top: 0;
    }

    img {
      max-width: 100px;
    }
  }

  &__link {
    font-family: $heading-font;
    width: 100%;
  }

  &__items {
    display: flex;
    @include mq($until: $header-breakpoint) {
      display: block;
      width: 100%;
    }
  }

  &__item {
    order: 1; // initially set all flex-orders to same value
    position: relative;

    + .c-nav__item {
      padding-left: spacing(1);
    }

    &.has-children {
      position: relative;
      z-index: z(children);

      @media (hover: hover), (-ms-high-contrast: none), (-ms-high-contrast: active) { // only use the hover styling when hover is supported
        &:hover {
          overflow: visible;

          .c-nav__submenu {
            max-height: 600px;
            overflow: visible;

            &::after {
              opacity: 1;
            }

            @include mq($until: $header-breakpoint) {
              margin-top: spacing(-1);
            }
          }
        }
      }

      .c-nav__submenu.is-active {
        max-height: 600px;
        overflow: visible;

        &::after {
          opacity: 1;
        }

        @include mq($until: $header-breakpoint) {
          margin-top: spacing(-1);
        }
      }
    }

    @include mq($until: $header-breakpoint) {
      width: 100%;
    }

  }

  &__submenu {
    background-color: $clr-white;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: shadow(0);
    max-height: 0;
    text-align: center;
    @include transition(0);

    // &::after {
    //   content: "";
    //   width: 0;
    //   height: 0;
    //   border-left: (spacing(0) / 2) solid transparent;
    //   border-right: (spacing(0) / 2) solid transparent;
    //   border-top: 0 solid transparent;
    //   border-bottom: (spacing(0) / 2) solid $clr-white;
    //   opacity: 0;
    //   display: block;
    //   position: absolute;
    //   top: -(spacing(-3));
    //   left: 0;
    // }

    li {
      a {
        display: block;
        padding: spacing(-2) spacing(0);
        text-transform: $text-none;
        color: $clr-text;
      }

      &:not(:last-child) {
        @include mq($from: md) {
          border-bottom: $border grey(-2);
        }
      }
    }

    @include mq($until: $header-breakpoint) {
      position: relative;
      box-shadow: none;
      top: auto;

      &::after {
        left: 0;
        transform: none;
      }

      li {
        a {
          text-align: left;
        }
      }
    }
  }


  &__toggle {
    display: inline-block;
    position: static;
    right: 0;
    top: spacing(0);
    border-left: 1px solid $clr-white;
    padding-left: spacing(0) / 2;

    svg {
      @extend %inline-svg;
      transition: transform .3s ease-in-out;
      fill: $clr-white;
    }

    &.is-active {
      svg {
        transform: rotate(180deg);
      }
    }

    @media (hover: hover), (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
    }

  }
}
