/**
* Meta menu styles
**/

.c-meta-menu {
  display: block;

  @include mq($from: md) {
    display: flex;
    flex-direction: row;
  }

  li {
    a {
      color: $clr-text;
      padding: spacing(-2);
      margin-left: -(spacing(-2));
      margin-right: -(spacing(-2));
      @include transition(0);
      border-top: $border grey(-2);
      display: block;

      &:hover,
      &:focus {
        color: primary(0);
      }

      @include mq($from: md) {
        padding: spacing(-3) spacing(-2);
        margin: 0;
        border: none;
      }
    }

    &.is-active {
      & > a {
        color: primary(0);
      }
    }
  }
}
