/**
* Text in box pagepart
**/

.pp-boxed-text {
  background-color: grey(-2);
  padding: spacing(0);

  @include mq($from: md) {
    padding: spacing(1);
  }

  h2,
  p {
    margin-top: 0;
  }
}
