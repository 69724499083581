/**
* Header component
**/
$header-breakpoint: md;
$header-toppadding: spacing(2);
$clr-header-bg: $clr-white;
$slidedown-speed: .3s;

.c-header {
  overflow: hidden;
  background-color: $clr-header-bg;
  position: relative;
  width: 100%;
  padding: $header-toppadding 0;

  @include mq($until: $header-breakpoint) {
    max-height: $header-height;
    max-height: var(--header-height);
    transition: max-height $slidedown-speed ease-in-out;
  }

  &__overlay {
    &:after {
      content: '';
      position: absolute;
      left: -100px;
      top: 100px;
      background: secondary(-3);
      width: 1000px;
      height: 350px;
      transform: rotate(45deg);
      padding-top: 100px;
      border-top: 6px solid primary(0);

      @include mq($until: lg) {
        left: -200px;
        height: 255px;  
      }

      @include mq($until: xs) {
        left: -230px;
      }
    }

  }


  &__container {
    @extend .o-container;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    max-width: 50%;
    position: relative;
    position: absolute;
    bottom: -10px;

    img,
    svg {
      position: relative;
      z-index: 2;
      vertical-align: bottom;
      max-width: 100%;
    }

    @include mq($until: lg) {
      max-width: 300px; 
    }

    @include mq($until: md) {
      max-width: 250px; 
      bottom: -40px;
    }

    @include mq($until: xs) {
      max-width: 230px;  
    }
  }

  &__menu-toggle {
    display: block;
    align-self: center;
    width: 30px;
    height: 30px;
    border: none;
    will-change: transform;
    transform: rotate(0deg);
    transition: transform .3s ease-in-out;
    cursor: pointer;
    background: transparent;
    margin-left: auto;
    top: $header-toppadding / 2;

    @include mq($from: $header-breakpoint) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $clr-white;
      border-radius: 0;
      opacity: 1;
      left: 0;
      will-change: transform;
      transform: rotate(0deg);
      transition: transform .3s ease-in-out, background-color .5s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    &.is-active {
      transform: rotate(180deg);

      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(10px, 10px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(10px, -10px);
        }
      }
    }
  }

  @include mq($from: $header-breakpoint) {
    padding-bottom: 0;
  }



  /**
  * Header states
  * - Modifier specific state styling is found under the respective modifier class
  **/

  // is-active added when mobile menu is opened
  &.is-active {
    position: fixed;
    top: 0;
    z-index: z-index(menu);
    max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    max-height: calc(var(--vh, 1vh) * 100);
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    overflow-x: hidden;
    background: primary(0);
    padding: spacing(0);

    .c-header__logo,
    .c-header__overlay {
      display: none;
    }

    .c-header__container {
      flex-direction: column;
      position: relative;
      align-items: initial;
      pointer-events: initial;

      .c-nav--primary,
      .c-nav--secondary {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: none;
        order: 1;
        width: 100%;
      }

      .c-nav__item {
        display: inline-block;
        padding: spacing(-2) 0;

        .c-nav__link {
          display: inline-block;
          width: auto;
        }

        .c-nav__toggle {
          position: absolute;
          right: auto;
          margin-left: spacing(0) / 2;
        }
      }

      .c-nav__item--search {
        order: 0;
        margin-left: unset;
      }

      .c-header__menu-toggle {
        position: absolute;
        right: $header-toppadding / 2;
        top: 9px;
        z-index: 99;
      }

      .c-nav__submenu {
        @media (hover: none) {
          &::after {
            display: none;
          }
        }
      }

    }
  }
}
