/**
* Footer component
**/

.c-footer {
  background: primary(0);
  margin-top: spacing(3);
  position: relative;
  background-color: primary(0);
  padding-top: spacing(1);
  color: $clr-white;
  font-family: $heading-font;
  font-weight: font-weight(1);
  line-height: line-height(2);

  h5 {
    font-size: font-size(0);
    margin-bottom: spacing(-2);
    color: primary(2);
    & + h5 {
      margin-top: spacing(0);
    }
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .c-nav__item {
    padding-left: 0;
  }

  &-menu {
    position: relative;
    &:after {
      content: url('../../static/go-kids.png');
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: .3;
    }
  }

  &__logo {
    img {
      max-width: 250px;
    }
  }

  &__contact,
  &__sitemap {
    @include mq($until: sm) {
      margin-top: spacing(0);
    }
  }

  &__contact {

    a {
      color: $clr-white;
    }

    svg {
      fill: $clr-white;
      width: 20px;
      height: 23px;
      margin-right: 10px;
    }

    &__item {
      display: flex;
      + .c-footer__contact__item {
        margin-top: spacing(-2);
      }
    }

    & + .c-footer__contact {
      margin-top: spacing(0);
    }
  }

  &__newsletter {
    @include mq($until: lg) {
      margin-top: spacing(0);
    }
  }

  &__socials {
    margin-top: spacing(0);
  }
}


/**
* Meta menu
**/

.c-footer-menu {



  li {
    a,
    span {
      color: $clr-white;
      display: inline-block;
    }

    span {
      margin-bottom: spacing(0);
    }

    a {
      @include transition(0);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}


/**
* Disclaimer
**/

.c-footer__secondary {
  background-color: secondary(-3);
  margin-top: spacing(1);
  padding-top: spacing(-1);
  padding-bottom: spacing(-1);
  color: $clr-text;
  font-family: $body-font;
  font-size: font-size(-1);
  font-weight: font-weight(0);

  .o-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include mq($from: sm) {
      flex-direction: row;
      text-align: unset;
    }
  }

  svg {
    margin: 0 5px;
  }



  ul {
    flex: 1 0 auto;
    align-self: flex-start;
    width: 100%;

    @include mq($from: sm) {
      width: auto;
    }

    a {
      color: $clr-text;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    li {
      display: block;
      margin-right: spacing(-1);
      

      @include mq($from: sm) {
        display: inline-block;
      }

      
    }
  }
}
