/**
* General functions
**/

@function z-index($key1, $key2: null) {
  $map: $z-index;
  $found-index: null;

  @if not map-has-key($map, $key1) {
    @error "Key doesn't exist `#{$key1}`.";
  }
  @else {
    $found-index: index(map-keys($map), $key1);

    @if ($key2) {
      $map: map-get($map, $key1);
      $found-index: index($map, $key2);
    }
  }

  @return $found-index;
}

@function z($key1, $key2: null) {
  @return z-index($key1, $key2);
}

@function font-size($key) {
  @return map-deep-get($font-size, $key);
}

@function font-weight($key) {
  @return map-deep-get($font-weight, $key);
}

@function line-height($key) {
  @return map-deep-get($line-height, $key);
}

@function shadow($key) {
  @return map-deep-get($shadow, $key);
}

@function transition($key) {
  @return map-deep-get($transition, $key);
}

@function breakpoint($key) {
  @return map-deep-get($mq-breakpoints, $key);
}



/**
* Color functions
* Add new color functions here
**/

@function primary($key) {
  @return color(primary, $key);
}

@function secondary($key) {
  @return color(secondary, $key);
}

@function third($key) {
  @return color(third, $key);
}

@function grey($key) {
  @return color(grey, $key);
}

@function confirm($key) {
  @return color(confirm, $key);
}

@function error($key) {
  @return color(error, $key);
}

@function warning($key) {
  @return color(warning, $key);
}

@function message($key) {
  @return color(message, $key);
}



/**
* Spacing functions
**/

@function spacing($key) {
  @return map-deep-get($global-spacings, $key);
}



/**
* Global map deep get - e.g.: map-deep-get($colors, primary, 0);
**/

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @error "Key `#{$key}` doesn't exist in `#{$map}`.";
    }
    $map: map-get($map, $key);
  }
  @return $map;
}



/**
* Colors map deep get - e.g.: color(primary, -1);
**/

@function color($keys...) {
  $map: $colors;
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @error "Key `#{$key}` doesn't exist in `#{$map}`.";
    }
    $map: map-get($map, $key);
  }
  @return $map;
}
